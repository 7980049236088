export type InsuranceServices =
  | PatientEligibilityMaximum
  | PatientEligibilityDeductible
  | PatientEligibilityLimitation
  | PatientEligibilityCoinsurance;

export enum PatientEligibilityCoverageLevel {
  Individual = "individual",
  Family = "family",
}

export interface PatientEligibilitySummary {
  payor_name: string;
  plan_name: string;
  status: string;
  expiration_date: string;
}

export interface PatientEligibilityServiceUsed {
  visit_date: string;
  services: string[];
}

export interface PatientEligibilityBenefitReminder {
  uses_remaining: number;
  service_name: string;
}

export enum EligibilityPlanPeriod {
  Calendar = "calendar",
  Lifetime = "lifetime",
}

export enum EligibilityNetwork {
  InNetwork = "in_network",
  OutNetwork = "out_of_network",
}

export interface PatientEligibilityDeductible {
  network: EligibilityNetwork;
  service_type: string;
  amount?: number;
  remaining?: number;
  lifetime?: number;
  lifetime_remaining?: number;
  plan_period: EligibilityPlanPeriod;
  payer_specific_description?: string;
}

export interface PatientEligibilityMaximum {
  network: EligibilityNetwork;
  service_type: string;
  amount?: number;
  remaining?: number;
  lifetime?: number;
  lifetime_remaining?: number;
  plan_period: EligibilityPlanPeriod;
  payer_specific_description?: string;
  coverage_level?: PatientEligibilityCoverageLevel;
}

export interface PatientEligibilityCoinsurance {
  network: EligibilityNetwork;
  service_type: string;
  percent: string;
  payor_specific: string;
}

export interface PatientEligibilityHealthServiceDescription {
  time_period_qualifier: string;
  time_period_value: string;
  quantity_qualifier: string;
  quantity: string;
}

export interface PatientEligibilityLimitation {
  service_type: string;
  limitation: string;
  health_service_description: PatientEligibilityHealthServiceDescription[];
}

export interface PatientEligibilityActiveCoverage {
  coverage_level: PatientEligibilityCoverageLevel;
  insurance_type: string;
  payor_specific_description: string;
}

export interface PatientEligibility {
  insurance_summary?: PatientEligibilitySummary;
  services_used_in_the_past_year?: PatientEligibilityServiceUsed[];
  benefit_reminder?: PatientEligibilityBenefitReminder[];
  deductible?: PatientEligibilityDeductible[];
  maximums?: PatientEligibilityMaximum[];
  coinsurance?: PatientEligibilityCoinsurance[];
  limitations?: PatientEligibilityLimitation[];
  active_coverage?: PatientEligibilityActiveCoverage[];
}

export interface PatientEligibilityResponse {
  message: string;
  data: PatientEligibility & { errors?: any };
}

export interface PatientInsurance {
  insurance: {
    first_name: string;
    last_name: string;
    dob: string;
    payer_id: string;
    member_id: string;
    is_dependent: boolean;
    group_number: string;
  };
}

export enum PatientRelationship {
  Self = "Self",
  Dependent = "Dependent",
}

export interface InsurancePayer {
  alt_payer_ids: string[];
  features: string[];
  id: string;
  name: string;
  onederfulPayerId: string;
  status: string;
}

export interface InsurancesPayersResponse {
  data: InsurancePayer[];
  message: string;
}

import {
  EligibilityNetwork,
  EligibilityPlanPeriod,
  InsuranceServices,
  PatientEligibility,
  PatientEligibilityCoinsurance,
  PatientEligibilityCoverageLevel,
  PatientEligibilityDeductible,
  PatientEligibilityMaximum,
} from "@kells/clinic-one/apis";
import { StringService } from "../../services";
import { isNil } from "lodash";

export const INSURANCE_LIST_MAX_LENGTH = 3;

export const CLEANINGABLE_SERVICES = ["prophylaxis"];

export enum InsuranceServiceType {
  Deductible = "Deductible",
  Maximum = "Maximum",
  Limitation = "Limitation",
  Coinsurance = "Coinsurance",
}

export const getPlanPeriodTitle = (planPeriod: EligibilityPlanPeriod) => {
  switch (planPeriod) {
    case EligibilityPlanPeriod.Calendar:
      return "Calendar Year";
    case EligibilityPlanPeriod.Lifetime:
      return "Lifetime";
    default:
      return "";
  }
};

export const getInsruanceServicesEmptyText = (
  service: InsuranceServiceType
) => {
  return `No ${getInsuranceServiceTitle(service)}`;
};

export const getInsuranceServiceTitle = (service: InsuranceServiceType) => {
  switch (service) {
    case InsuranceServiceType.Deductible:
      return "Deductibles";
    case InsuranceServiceType.Maximum:
      return "Maximums";
    case InsuranceServiceType.Limitation:
      return "Limitations";
    case InsuranceServiceType.Coinsurance:
      return "Coverage";
    default:
      return "";
  }
};

const formatServiceName = (serviceName?: string) => {
  if (!serviceName) return "";

  return StringService.toTitleCase(serviceName);
};

const calculateAmountUsed = (totalAmount: number, amountRemaining?: number) => {
  if (!amountRemaining) return 0;

  return totalAmount - amountRemaining;
};

const calculateAmountUsedPercantage = (
  totalAmount: number,
  amountRemaining: number
) => {
  const amountUsed = calculateAmountUsed(totalAmount, amountRemaining);

  return (amountUsed / totalAmount) * 100;
};

const formatNumber = (number?: number) => {
  if (isNil(number)) return "";

  return Number(number).toFixed(2);
};

const getServiceTypeTitle = (serviceType: string) => {
  let formattedServiceType = serviceType;

  switch (serviceType) {
    case "prophylaxis":
      formattedServiceType = "Prophylaxis/Cleaning";
      break;

    default:
      formattedServiceType = serviceType;
      break;
  }

  return formatServiceName(formattedServiceType);
};

function groupByPlanType<T extends InsuranceServices>(data: T[] = []): T[] {
  const groupedData: Record<string, T[]> = {};
  const orderOfPlanTypes: string[] = [];

  // Group items by service_type while maintaining the order of first occurrence
  data.forEach((item) => {
    const planType =
      (item as PatientEligibilityDeductible | PatientEligibilityMaximum)
        .payer_specific_description ??
      (item as PatientEligibilityCoinsurance).payor_specific ??
      "";

    if (!groupedData[planType]) {
      groupedData[planType] = [];
      orderOfPlanTypes.push(planType);
    }

    groupedData[planType].push(item);
  });

  // Determine the template order from items with an empty `planType`
  const templateOrder = groupedData[""]?.map((item) => item.service_type) || [];

  // Sort each group based on the template order
  orderOfPlanTypes.forEach((planType) => {
    groupedData[planType].sort((a, b) => {
      const indexA = templateOrder.indexOf(a.service_type);
      const indexB = templateOrder.indexOf(b.service_type);

      // If a service_type is not in the template, push it to the end
      if (indexA === -1 && indexB === -1) {
        return a.service_type.localeCompare(b.service_type);
      } else if (indexA === -1) {
        return 1; // Item not in template comes after items in template
      } else if (indexB === -1) {
        return -1;
      }

      return indexA - indexB;
    });
  });

  return orderOfPlanTypes.flatMap((type) => groupedData[type]);
}

const mapServiceResponse = (
  service: PatientEligibilityMaximum | PatientEligibilityDeductible
) => {
  const amount = service.amount ?? service.lifetime ?? 0;
  const amountRemaining =
    service.remaining ?? service.lifetime_remaining ?? amount;

  return {
    ...service,
    network: service.network ?? EligibilityNetwork.InNetwork,
    plan_period: getPlanPeriodTitle(service.plan_period),
    service_type: getServiceTypeTitle(service.service_type),
    amount: formatNumber(amount),
    amountRemaining: formatNumber(amountRemaining),
    amountUsed: formatNumber(calculateAmountUsed(amount, amountRemaining)),
    amountUsedPercantage: formatNumber(
      calculateAmountUsedPercantage(amount, amountRemaining)
    ),
  };
};

const mapMaximumResponse = (maximum: PatientEligibilityMaximum) => {
  return {
    ...mapServiceResponse(maximum),
    coverage_level:
      maximum.coverage_level ?? PatientEligibilityCoverageLevel.Individual,
  };
};

const filterService = (service: InsuranceServices) => !!service.service_type;

export const mapPatientEligibilityResponse = (
  eligibility: PatientEligibility | null
) => {
  if (!eligibility) return null;

  return {
    ...eligibility,
    deductible: groupByPlanType(eligibility.deductible)
      ?.filter(filterService)
      .map(mapServiceResponse),
    maximums: groupByPlanType(eligibility.maximums)
      ?.filter(filterService)
      .map(mapMaximumResponse),
    limitations: groupByPlanType(eligibility.limitations)
      ?.filter(filterService)
      .map((limitation) => ({
        ...limitation,
        service_type: getServiceTypeTitle(limitation.service_type),
      })),
    coinsurance: groupByPlanType(eligibility.coinsurance)
      ?.filter(filterService)
      .map((coinsurance) => ({
        ...coinsurance,
        network: coinsurance.network ?? EligibilityNetwork.InNetwork,
        service_type: getServiceTypeTitle(coinsurance.service_type),
      })),
    services_used_in_the_past_year: eligibility.services_used_in_the_past_year?.map(
      (service) => ({
        ...service,
        services: service.services.map((service) => formatServiceName(service)),
      })
    ),
  };
};

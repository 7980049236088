import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { SharedService } from "../shared/shared.service";

@Injectable()
export class PortalHttpInterceptor implements HttpInterceptor {
  constructor(private sharedService: SharedService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const portal = this.sharedService.localStoragePortal;

    if (portal) {
      const clonedRequest = req.clone({
        setHeaders: {
          Portal: portal,
        },
      });
      return next.handle(clonedRequest);
    }

    return next.handle(req);
  }
}

import { InsuranceNames } from "../enums/insurance-names.enum";
import { OrganizationNames } from "../enums/organization-names.enum";
import {
  DataHiddenConfig,
  ReportDataHiddenConfig,
} from "../models/hidden-data.models";

export const DATA_HIDDEN_CONFIG: DataHiddenConfig = {
  TALK_TO_A_DENTIST: {
    organizations: [
      OrganizationNames.Lantnerdds,
      OrganizationNames.DentalSavers,
      OrganizationNames.OralLink,
    ],
    insurances: [],
  },
  PRODUCT_RECOMMENDATIONS: {
    organizations: [OrganizationNames.Aetna, OrganizationNames.OralLink],
    insurances: [],
  },
  DENTAL_ADVISOR: {
    organizations: [OrganizationNames.Aetna, OrganizationNames.OralLink],
    insurances: [],
  },
  ASSISTANT_PANEL: {
    organizations: [OrganizationNames.OralLink],
    insurances: [],
  },
  TREATMENT_VERIFICATION: {
    organizations: [
      OrganizationNames.Lantnerdds,
      OrganizationNames.DentalSavers,
      OrganizationNames.OralLink,
    ],
    insurances: [],
  },
  PHOTO_SCAN_INTAKE_FORM: {
    organizations: [OrganizationNames.OralLink],
    insurances: [],
  },
  INSURANCE: {
    organizations: [OrganizationNames.OralLink],
    insurances: [],
  },
};

export const XRAY_REPORT_DATA_HIDDEN_CONFIG: ReportDataHiddenConfig = {
  ESTIMATED_COSTS: {
    organizations: [
      OrganizationNames.LibertyDental,
      OrganizationNames.Villagecare,
      OrganizationNames.KareMobile,
      OrganizationNames.OralLink,
    ],
    insurances: [InsuranceNames.LibertyDental],
  },
  ZOC_DOC: {
    organizations: [
      OrganizationNames.Lantnerdds,
      OrganizationNames.Aetna,
      OrganizationNames.OralLink,
    ],
    insurances: [],
  },
  POTENTIAL_ACTIONS: {
    organizations: [
      OrganizationNames.LibertyDental,
      OrganizationNames.Villagecare,
    ],
    insurances: [InsuranceNames.LibertyDental],
  },
  PRODUCT_RECOMMENDATIONS: {
    organizations: [OrganizationNames.Aetna, OrganizationNames.OralLink],
    insurances: [],
  },
  FINDING_CATEGORY: {
    organizations: [],
    insurances: [],
  },
};

export const PHOTO_REPORT_DATA_HIDDEN_CONFIG: ReportDataHiddenConfig = {
  ESTIMATED_COSTS: {
    organizations: [
      OrganizationNames.LibertyDental,
      OrganizationNames.Villagecare,
      OrganizationNames.KareMobile,
      OrganizationNames.OralLink,
    ],
    insurances: [InsuranceNames.LibertyDental],
  },
  ZOC_DOC: {
    organizations: [
      OrganizationNames.Lantnerdds,
      OrganizationNames.Aetna,
      OrganizationNames.DentalSavers,
      OrganizationNames.OralLink,
    ],
    insurances: [],
  },
  POTENTIAL_ACTIONS: {
    organizations: [
      OrganizationNames.LibertyDental,
      OrganizationNames.Villagecare,
      OrganizationNames.DentalSavers,
    ],
    insurances: [InsuranceNames.LibertyDental],
  },
  PRODUCT_RECOMMENDATIONS: {
    organizations: [OrganizationNames.Aetna, OrganizationNames.OralLink],
    insurances: [],
  },
  FINDING_CATEGORY: {
    organizations: [OrganizationNames.DentalSavers],
    insurances: [],
  },
};

export const getReportDataHiddenConfig = (isXrayReport: boolean) =>
  isXrayReport
    ? XRAY_REPORT_DATA_HIDDEN_CONFIG
    : PHOTO_REPORT_DATA_HIDDEN_CONFIG;

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Treatment, TreatmentService } from "@kells/clinic-one/apis";
import { KellsFinding } from "@kells/interfaces/finding";
import { KellsImageBase } from "@kells/interfaces/image";
import { observeProperty } from "@kells/utils/angular";
import { keepDefined } from "@kells/utils/observable/observable-operators";
import { flatten } from "lodash-es";
import { Observable, combineLatest, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
import { ADVANCED, MODERATE_ONLY, INITIAL_ONLY } from "../theme/colors";
import { MatDialog } from "@angular/material/dialog";
import { VideoDialogComponent } from "./../../../../../../../apps/clinic-one/src/app/shared/components/video-modal/video-dialog.component";
import { TreatmentPlanTableColumnNames } from "../../enums/treatment-plan-table-column-names.enum";
import { ReportService } from "../../services/report.service";

@Component({
  selector: "koa-treatment-plan-table",
  templateUrl: "./treatment-plan-table.component.html",
  styleUrls: ["./treatment-plan-table.component.scss"],
})
export class TreatmentPlanTableComponent implements OnInit, OnDestroy {
  @Input() images: KellsImageBase[];
  private images$: Observable<KellsImageBase[]> = observeProperty(
    this,
    "images"
  ).pipe(keepDefined());

  @ViewChild("loadingContentFallback") loadingContentFallback: TemplateRef<any>;

  @Input() treatments: Treatment[] | undefined;
  private treatments$: Observable<Treatment[]> = observeProperty(
    this,
    "treatments"
  ).pipe(keepDefined());

  @Input() loading = true;

  /** A list of all findings from the provided images. */
  private reportFindings$: Observable<KellsFinding[]> = this.images$.pipe(
    map((images) => flatten(images?.map((g) => g.findings)) ?? [])
  );

  treatmentTableEntries$ = combineLatest([
    this.treatments$.pipe(keepDefined()),
    this.reportFindings$.pipe(keepDefined()),
  ]).pipe(
    map(([treatments, reportFindings]) => {
      this.isDataReady$.next(true);

      const elements = this.treatmentService.computeTreatmentTableRows(
        treatments,
        reportFindings
      );

      if (elements) {
        this.loading = false;
        this.isDataReady$.next(true);
      }

      return elements;
    })
  );
  isDataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _subs = new SubSink();
  constructor(
    private treatmentService: TreatmentService,
    public dialog: MatDialog,
    public reportService: ReportService
  ) {}

  ngOnInit() {
    this._subs.sink = this.treatmentTableEntries$.subscribe();
  }

  openDialog(treatmentPlan: string): void {
    this.dialog.open(VideoDialogComponent, {
      width: "600px",
      data: { name: treatmentPlan },
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
  @Input()
  displayedColumns: TreatmentPlanTableColumnNames[];

  public readonly TREATMENT_PLAN_TABLE_COLUMN_NAMES = TreatmentPlanTableColumnNames;

  COLOR_PALETTE_ADVANCED = ADVANCED;
  COLOR_PALETTE_INITIAL = INITIAL_ONLY;
  COLOR_PALETTE_MODERATE = MODERATE_ONLY;

  public isColumnVisibile(columnName: TreatmentPlanTableColumnNames): boolean {
    return this.displayedColumns?.includes(columnName);
  }

  public get columnSpanWidthClassName(): string {
    return `col-span-${12 / this.displayedColumns.length}`;
  }

  public hasVideo(treatmentPlanName: string): boolean {
    const videoList = this.reportService.getVideoList();
    const dentistryVideos = videoList?.length ? videoList[0].videos : [];
    const matchedVideo = dentistryVideos.find((video) =>
      video.id.includes(treatmentPlanName.toLowerCase().split(" ").join("_"))
    );

    return !!matchedVideo;
  }
}

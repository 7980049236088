import { Component, Input, OnDestroy } from "@angular/core";
import { RecommendedProduct } from "../../shared/interfaces/recommended-product.interfaces";
import { AnalyticsService } from "@kells/apis/analytics";
import { OpenProductLinkEvent } from "apps/patient-reporting/src/app/shared/models/analytics/home-events";
import { DataAccessService } from "@kells/apis/data-access";
import { SubSink } from "subsink";
import { RecommendedProductDiscountModalComponent } from "../recommended-product-discount-modal/recommended-product-discount-modal.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "kpr-recommended-product-item",
  templateUrl: "./recommended-product-item.component.html",
  styleUrls: ["./recommended-product-item.component.scss"],
})
export class RecommendedProductItemComponent implements OnDestroy {
  @Input() recommendedProduct: RecommendedProduct;

  public get productLink() {
    return (
      this.recommendedProduct.brandAffiliateLink ??
      this.recommendedProduct.amazonAffiliateLink ??
      this.recommendedProduct.retailLink
    );
  }

  private _subs = new SubSink();

  constructor(
    public readonly data: DataAccessService,
    private analyticsService: AnalyticsService,
    private dialog: MatDialog
  ) {
    this._subs.sink = this.data.getProducts().subscribe();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public onShopNowClick(event: Event) {
    if (this.recommendedProduct.discount?.type === "coupon") {
      event.preventDefault();

      this.dialog.open(RecommendedProductDiscountModalComponent, {
        panelClass: "patient-reporting-modal",
        backdropClass: "patient-reporting-modal-backdrop",
        maxWidth: "600px",
        width: "100%",
        data: {
          product: {
            ...this.recommendedProduct,
            productLink: this.productLink,
          },
        },
      });
    } else {
      this.analyticsService.record(
        OpenProductLinkEvent({
          productName: this.recommendedProduct.name,
          productLink: this.productLink,
        })
      );
    }
  }
}

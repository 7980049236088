/**
 * Given a file, returns a promise of its content in base64 format.
 *
 * @param file the file to convert.
 * @returns a promise of its base64 content.
 */
export function toImageBase64(file: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = (reader.result as string)
        .replace("data:", "")
        .replace(/^.+,/, "");
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
}

export async function fetchImageAsBase64(
  imageUrl: string
): Promise<string | null> {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result as string | null);
      reader.onerror = reject;
    });
  } catch (error) {
    console.error("Error fetching image:", error);
    return null;
  }
}

import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SwiperService } from "../../../services";
import { DataAccessService } from "@kells/apis/data-access";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ModalData } from "@kells/utils/angular";
import {
  OnboardingSlide,
  SlideItemType,
} from "../../models/onboarding-slide.model";
import { AnalyticsService } from "@kells/apis/analytics";
import {
  OnboardingFinalizedEvent,
  OnboardingNavigateBackwardEvent,
  OnboardingNavigateForwardEvent,
  OnboardingNavigateToSlideEvent,
  OnboardingOpenedEvent,
  OnboardingRevertedEvent,
  OnboardingStartedEvent,
} from "apps/patient-reporting/src/app/shared/models/analytics/home-events";
import { SubSink } from "subsink";
import { map } from "rxjs/operators";
import { isDataHidden } from "libs/oral-analytics/report/src/lib/utils/data-hidden.utils";
import { HiddenData } from "libs/oral-analytics/report/src/lib/models/hidden-data.models";

@Component({
  selector: "kpr-onboarding-modal",
  templateUrl: "./onboarding-modal.component.html",
  styleUrls: ["./onboarding-modal.component.scss"],
})
export class OnboardingModalComponent implements OnInit, AfterViewInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild("swiper", { static: false }) swiper: any;

  isActivated = false;

  private _subs = new SubSink();

  public ONBOARDING_SLIDES: OnboardingSlide[];

  public slideItemType = SlideItemType;

  public readonly patientWithOrganizations$ = this.data
    .patientWithOrganizations$;

  public readonly hiddenData$ = this.patientWithOrganizations$.pipe(
    map((patientWithOrganizations) => isDataHidden(patientWithOrganizations))
  );

  public readonly isTreatmentVerificationVisible$ = this.hiddenData$.pipe(
    map((hiddenData) => !hiddenData.treatmentVerification)
  );

  public get currentSlideIndex(): number {
    return this.swiper?.nativeElement?.swiper.activeIndex;
  }

  public get isPrevSlideVisible(): boolean {
    return (
      (!!this.currentSlideIndex || this.isActivated) &&
      this.currentSlideIndex !== this.ONBOARDING_SLIDES.length - 1
    );
  }

  public get isNextSlideVisible(): boolean {
    return (
      this.currentSlideIndex !== this.ONBOARDING_SLIDES.length - 1 ||
      !this.isActivated
    );
  }
  constructor(
    private readonly swiperService: SwiperService,
    public readonly data: DataAccessService,
    public dialogRef: MatDialogRef<OnboardingModalComponent>,
    private analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public isAetna: ModalData
  ) {}
  ngOnInit(): void {
    this.analyticsService.record(OnboardingOpenedEvent({}));

    this._subs.sink = this.hiddenData$.subscribe((hiddenData) => {
      this.initSlides(hiddenData);
    });
  }

  ngAfterViewInit(): void {
    const params = {
      injectStyles: [
        `
          .swiper-pagination-bullet {
            display: inline-block;
            width: 62px;
            height: 6px;
            border-radius: 3px;
            background: #B6EADF;
          }
          .swiper-pagination-bullet-active {
            background: #49DBC6;
          }

          @media screen and (max-width: 479px) {
            .swiper-pagination-bullet {
              width: 38px;
            }
          }
        `,
      ],
      pagination: {
        clickable: true,
        renderBullet: function (index: number, className: string) {
          return `<span class=${className}></span>`;
        },
      },
    };

    this.swiperService.init(this.swiper.nativeElement, params);
  }

  private initSlides(hiddenData: HiddenData) {
    this.ONBOARDING_SLIDES = [
      {
        key: SlideItemType.AIPhotoScan,
        image: "assets/onboarding-ai-scan.png",
        header: "Virtual Checkup",
        title: "Get an easy dental checkup using your phone.",
      },
      {
        key: SlideItemType.ViewReports,
        image: "assets/onboarding-view-reports.png",
        header: "View Reports",
        title: `Review KELLS report to understand your oral health status. You can get one by using Virtual Checkup${
          !hiddenData.treatmentVerification ? " or Second Opinion" : ""
        }.`,
      },
      ...(!hiddenData.treatmentVerification
        ? [
            {
              key: SlideItemType.TreatmentVerification,
              image: "assets/onboarding-second-opinion.png",
              header: "Second Opinion",
              title:
                "Submit X-rays to get an unbiased evaluation or confirm a treatment plan.",
            },
          ]
        : []),
      ...(!hiddenData.talkToADentist
        ? [
            {
              key: SlideItemType.TalkToDentist,
              image: "assets/onboarding-talk-to-dentist.png",
              header: "Talk to a Dentist",
              title:
                "Connect to a licensed dentist via video for emergency visit",
            },
          ]
        : []),
    ];
  }

  public onPrevSlide(): void {
    if (this.isActivated && !this.currentSlideIndex) {
      this.analyticsService.record(OnboardingRevertedEvent({}));
      this.isActivated = false;
      return;
    }
    this.analyticsService.record(OnboardingNavigateBackwardEvent({}));
    this.swiper.nativeElement.swiper.slidePrev();
  }
  public onNextSlide(): void {
    if (!this.isActivated) {
      this.analyticsService.record(OnboardingStartedEvent({}));
      this.isActivated = true;
      return;
    }
    this.analyticsService.record(OnboardingNavigateForwardEvent({}));
    this.swiper.nativeElement.swiper.slideNext();
  }

  public navigateToSlide(slideItemType: SlideItemType): void {
    if (!this.isActivated) {
      this.analyticsService.record(OnboardingStartedEvent({}));
      this.isActivated = true;
      return;
    }
    const slideNumber = this.ONBOARDING_SLIDES.findIndex(
      (slide) => slide.key === slideItemType
    );

    this.analyticsService.record(
      OnboardingNavigateToSlideEvent({ slideNumber })
    );
    this.swiper.nativeElement.swiper.slideTo(slideNumber);
  }

  finalize() {
    this.analyticsService.record(OnboardingFinalizedEvent({}));
    this.dialogRef.close(true);
  }
}

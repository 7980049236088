import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StringService {
  static toTitleCase(str: string) {
    return str
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
      .replace(/[-_]+(.)/g, (_, c) => " " + c.toLocaleLowerCase());
  }
}

<div class="product">
  <div class="product__image-container">
    <img
      [src]="recommendedProduct?.image"
      alt="Product"
      class="product__image"
    />
  </div>
  <div class="product__description">
    <div>
      <div class="product__brand">
        {{ recommendedProduct?.brand }}
      </div>

      <div class="product__title">
        {{ recommendedProduct?.name }}
      </div>

      <div
        class="product__subcategory"
        *ngIf="recommendedProduct?.subCategory?.length"
      >
        <div class="product__subcategory-title">Best For:</div>
        <div class="product__subcategory-list">
          {{ recommendedProduct.subCategory.join(", ") }}
        </div>
      </div>
    </div>

    <div class="product_links">
      <div *ngIf="recommendedProduct?.discount">
        <div class="product__discount">
          <div class="product__discount-title">
            <img src="assets/sale.svg" alt="Sale" />
            <span> {{ recommendedProduct?.discount?.amount }}% OFF </span>
          </div>
        </div>
      </div>

      <a
        (click)="onShopNowClick($event)"
        [href]="productLink"
        target="_blank"
        class="product__description__link"
        >BUY NOW</a
      >
    </div>
  </div>
</div>

import { Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { RecommendedProduct } from "../../shared/interfaces/recommended-product.interfaces";
import {
  CopyProductCouponEvent,
  OpenProductDiscountModalEvent,
  OpenProductLinkEvent,
} from "apps/patient-reporting/src/app/shared/models/analytics/home-events";
import { AnalyticsService } from "@kells/apis/analytics";

type ModalData = {
  product: RecommendedProduct & { productLink: string };
};

@Component({
  selector: "koa-recommended-product-discount-modal",
  templateUrl: "./recommended-product-discount-modal.component.html",
  styleUrls: ["./recommended-product-discount-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendedProductDiscountModalComponent {
  public readonly couponCode = "KELLS";

  public isCouponCopied = false;

  constructor(
    public readonly dialogRef: MatDialogRef<RecommendedProductDiscountModalComponent>,
    private readonly analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public readonly data: ModalData
  ) {
    this.analyticsService.record(
      OpenProductDiscountModalEvent({
        productName: this.data.product.name,
        productLink: this.data.product.productLink,
      })
    );
  }

  public onShopNowClick() {
    this.closeDialog();
    this.analyticsService.record(
      OpenProductLinkEvent({
        productName: this.data.product.name,
        productLink: this.data.product.productLink,
      })
    );
  }

  public onCouponCopied() {
    this.isCouponCopied = true;
    this.analyticsService.record(
      CopyProductCouponEvent({
        productName: this.data.product.name,
        productLink: this.data.product.productLink,
      })
    );
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}

import { ReviewJob } from "@kells/clinic-one/data-access/review-jobs";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import * as ReviewJobActions from "./review-job.actions";

export const featureKey = "reviewJobs";

export interface ReviewJobState extends EntityState<ReviewJob> {
  isGeneratingReport: boolean;
}

const adapter = createEntityAdapter<ReviewJob>();

export const initialState = adapter.getInitialState({
  isGeneratingReport: false,
});

export const reducer = createReducer(
  initialState,
  on(ReviewJobActions.getAllReviewJobsSuccess, (state, { reviewJobs }) =>
    adapter.upsertMany(
      reviewJobs.map((r) => ({ ...r, patient: undefined })),
      state
    )
  ),
  on(ReviewJobActions.completeReviewJobSuccess, (state, { reviewJobId }) =>
    adapter.updateOne({ id: reviewJobId, changes: { completed: true } }, state)
  ),
  on(ReviewJobActions.updateReviewJobProvider, (state) => ({
    ...state,
    isGeneratingReport: true,
  })),
  on(ReviewJobActions.updateReviewJobProviderSuccess, (state) => ({
    ...state,
    isGeneratingReport: false,
  })),
  on(ReviewJobActions.updateReviewJobProviderFailure, (state) => ({
    ...state,
    isGeneratingReport: false,
  }))
);

export const { selectAll: selectAllReviewJobs } = adapter.getSelectors();

import { createEventsFromSchematics, eventDetail } from "@kells/apis/analytics";
import { InsuranceServiceType } from "../../utils";

interface OpenReportEventInterface {
  patientId: string;
  sessionDate: string;
}

interface OpenProductLinkEventInterface {
  product: string;
  productLink: string;
}

interface InsuranceDetailsModalEventInterface {
  service: InsuranceServiceType;
}

enum EventTypes {
  HomePageOpened = "Home page has been opened",

  OpenSecondOpinion = "Second Opinion opened",

  NavigateToPaymentLink = "Navigate to Stripe Payment Link",

  OpenPaymentSuccessModal = "Payment success modal opened",

  OpenAiPhotoScan = "Ai dental Scan opened",

  OpenTalkToDentist = "Confirmation modal for Talk To Dentist opened",
  ConfirmTalkToDentist = "Talk To Dentist open confirmed",
  CloseTalkToDentist = "Confirmation modal for Talk To Dentist cancelled",

  OpenReport = "Report is opened",

  OpenProductRecommendation = "Product recommendation is opened",

  InfoCollectionOpened = "Info collection modal opened [Info Collection]",
  InfoCollectionCompleted = "Info collection modal completed [Info Collection]",

  OnboardingOpened = "Onboarding has been opened [Onboarding]",
  ManualOnboardingTriggered = "Onboarding has been triggered manually [Onboarding]",
  OnboardingStarted = "Onboarding process started [Onboarding]",
  OnboardingReverted = "Onboarding process reverted [Onboarding]",
  OnboardingNavigateForward = "Onboarding navigated forward [Onboarding]",
  OnboardingNavigateBackward = "Onboarding navigated backward [Onboarding]",
  OnboardingNavigateToSlide = "Onboarding navigated to slide [Onboarding]",
  OnboardingClosed = "Onboarding has been closed [Onboarding]",
  OnboardingFinalized = "Onboarding has been completed [Onboarding]",

  OpenProductLinkEvent = "Product link opened [Products Recommendation]",
  OpenProductDiscountModalEvent = "Product discount modal opened [Products Recommendation]",
  CopyProductCouponEvent = "Product coupon copied [Products Recommendation]",

  OpenReportFromDentalRisk = "Open report from dental risk",

  OpenDentalScanFromDentalRisk = "Open dental scan from dental risk",

  OpenVoiceflowChatFromDashboard = "Voiceflow chat opened from dashboard",

  // Insurance
  SavingPlanReferralClick = "saving_plan_referral_click",
  InsuranceInfoModalOpen = "Insurance information modal open",
  InsuranceInfoModalClose = "Insurance information modal close",
  InsuranceServiceDetailsModalOpen = "Insurance service details modal open",
  InsuranceServiceDetailsModalClose = "Insurance service details modal close",
  InsuranceFormModalOpen = "Insurance form modal open",
  InsuranceFormModalClose = "Insurance form modal close",
  InsuranceSubmit = "Insurance submit",
  InsuranceConnectSuccessModalOpen = "Insurance connect success modal open",
  InsuranceConnectSuccessModalClose = "Insurance connect success modal close",
  InsuranceConnectFailModalOpen = "Insurance connect fail modal open",
  InsuranceConnectFailModalClose = "Insurance connect fail modal close",
}

const eventCreators = createEventsFromSchematics({
  HomePageOpenedEvent: {
    displayedName: EventTypes.HomePageOpened,
  },

  OpenSecondOpinionEvent: {
    displayedName: EventTypes.OpenSecondOpinion,
  },

  NavigateToPaymentLink: {
    displayedName: EventTypes.NavigateToPaymentLink,
  },

  OpenPaymentSuccessModal: {
    displayedName: EventTypes.OpenPaymentSuccessModal,
  },

  OpenAiPhotoScanEvent: {
    displayedName: EventTypes.OpenAiPhotoScan,
  },

  OpenTalkToDentistEvent: {
    displayedName: EventTypes.OpenTalkToDentist,
  },
  ConfirmTalkToDentistEvent: {
    displayedName: EventTypes.ConfirmTalkToDentist,
  },
  CloseTalkToDentistEvent: {
    displayedName: EventTypes.CloseTalkToDentist,
  },

  OpenReportEvent: {
    displayedName: EventTypes.OpenReport,
    detail: eventDetail<OpenReportEventInterface>(),
  },

  OpenProductLinkEvent: {
    displayedName: EventTypes.OpenProductLinkEvent,
    detail: eventDetail<OpenProductLinkEventInterface>(),
  },

  OpenProductDiscountModalEvent: {
    displayedName: EventTypes.OpenProductDiscountModalEvent,
    detail: eventDetail<OpenProductLinkEventInterface>(),
  },

  CopyProductCouponEvent: {
    displayedName: EventTypes.CopyProductCouponEvent,
    detail: eventDetail<OpenProductLinkEventInterface>(),
  },

  OpenProductRecommendationEvent: {
    displayedName: EventTypes.OpenProductRecommendation,
  },

  InfoCollectionOpenedEvent: {
    displayedName: EventTypes.InfoCollectionOpened,
  },
  InfoCollectionCompletedEvent: {
    displayedName: EventTypes.InfoCollectionCompleted,
  },

  OnboardingOpenedEvent: {
    displayedName: EventTypes.OnboardingOpened,
  },
  ManualOnboardingTriggeredEvent: {
    displayedName: EventTypes.ManualOnboardingTriggered,
  },
  OnboardingStartedEvent: {
    displayedName: EventTypes.OnboardingStarted,
  },
  OnboardingRevertedEvent: {
    displayedName: EventTypes.OnboardingReverted,
  },
  OnboardingNavigateForwardEvent: {
    displayedName: EventTypes.OnboardingNavigateForward,
  },
  OnboardingNavigateBackwardEvent: {
    displayedName: EventTypes.OnboardingNavigateBackward,
  },
  OnboardingNavigateToSlideEvent: {
    displayedName: EventTypes.OnboardingNavigateToSlide,
    detail: eventDetail<number>(),
  },
  OnboardingClosedEvent: {
    displayedName: EventTypes.OnboardingClosed,
  },
  OnboardingFinalizedEvent: {
    displayedName: EventTypes.OnboardingFinalized,
  },

  OpenVoiceflowChatFromDashboardEvent: {
    displayedName: EventTypes.OpenVoiceflowChatFromDashboard,
  },

  OpenReportFromDentalRisk: {
    displayedName: EventTypes.OpenReportFromDentalRisk,
  },

  OpenDentalScanFromDentalRisk: {
    displayedName: EventTypes.OpenDentalScanFromDentalRisk,
  },

  // Insurance
  SavingPlanReferralClick: {
    displayedName: EventTypes.SavingPlanReferralClick,
  },
  InsuranceInfoModalOpen: {
    displayedName: EventTypes.InsuranceInfoModalOpen,
  },
  InsuranceInfoModalClose: {
    displayedName: EventTypes.InsuranceInfoModalClose,
  },
  InsuranceServiceDetailsModalOpen: {
    displayedName: EventTypes.InsuranceServiceDetailsModalOpen,
    detail: eventDetail<InsuranceDetailsModalEventInterface>(),
  },
  InsuranceServiceDetailsModalClose: {
    displayedName: EventTypes.InsuranceServiceDetailsModalClose,
    detail: eventDetail<InsuranceDetailsModalEventInterface>(),
  },
  InsuranceFormModalOpen: {
    displayedName: EventTypes.InsuranceFormModalOpen,
  },
  InsuranceFormModalClose: {
    displayedName: EventTypes.InsuranceFormModalClose,
  },
  InsuranceSubmit: {
    displayedName: EventTypes.InsuranceSubmit,
  },
  InsuranceConnectSuccessModalOpen: {
    displayedName: EventTypes.InsuranceConnectSuccessModalOpen,
  },
  InsuranceConnectSuccessModalClose: {
    displayedName: EventTypes.InsuranceConnectSuccessModalClose,
  },
  InsuranceConnectFailModalOpen: {
    displayedName: EventTypes.InsuranceConnectFailModalOpen,
  },
  InsuranceConnectFailModalClose: {
    displayedName: EventTypes.InsuranceConnectFailModalClose,
  },
});

export const {
  HomePageOpenedEvent,
  OpenSecondOpinionEvent,
  OpenAiPhotoScanEvent,
  OpenTalkToDentistEvent,
  ConfirmTalkToDentistEvent,
  CloseTalkToDentistEvent,
  OpenReportEvent,
  OpenProductRecommendationEvent,
  InfoCollectionOpenedEvent,
  InfoCollectionCompletedEvent,
  NavigateToPaymentLink,
  OnboardingOpenedEvent,
  ManualOnboardingTriggeredEvent,
  OnboardingStartedEvent,
  OnboardingRevertedEvent,
  OnboardingNavigateForwardEvent,
  OnboardingNavigateBackwardEvent,
  OpenPaymentSuccessModal,
  OnboardingNavigateToSlideEvent,
  OnboardingClosedEvent,
  OnboardingFinalizedEvent,
  OpenProductLinkEvent,
  OpenVoiceflowChatFromDashboardEvent,
  OpenReportFromDentalRisk,
  OpenDentalScanFromDentalRisk,
  InsuranceInfoModalOpen,
  InsuranceInfoModalClose,
  InsuranceFormModalOpen,
  InsuranceFormModalClose,
  InsuranceSubmit,
  InsuranceConnectSuccessModalOpen,
  InsuranceConnectSuccessModalClose,
  InsuranceConnectFailModalOpen,
  InsuranceConnectFailModalClose,
  InsuranceServiceDetailsModalOpen,
  InsuranceServiceDetailsModalClose,
  OpenProductDiscountModalEvent,
  CopyProductCouponEvent,
  SavingPlanReferralClick,
} = eventCreators;

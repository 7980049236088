import { createAction, props } from "@ngrx/store";

export enum ActionTypes {
  LOGIN = "[Auth] Login",
  LOGIN_SUCCESS = "[Auth] Login Success",
  LOGIN_FAILURE = "[Auth] Login Failure",

  AUTHENTICATED = "[Auth] User Authenticated",

  RefreshAccessToken = "[Auth] Refresh Access Token",
  RefreshAccessTokenSuccess = "[Auth] Refresh Access Token Success",
  RefreshAccessTokenFailure = "[Auth] Refresh Access Token Failure",

  LOGOUT = "[Auth] Logout",
}

export const login = createAction(
  ActionTypes.LOGIN,
  props<{ email: string; password: string }>()
);

export const loginSuccess = createAction(
  ActionTypes.LOGIN_SUCCESS,
  props<{
    accessToken: string;
    tokenExpiresInSecs: number;
    refreshToken: string;
    userId: string;
    userOfficeId: string;
    userEmail: string;
  }>()
);

export const loginFailure = createAction(
  ActionTypes.LOGIN_FAILURE,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>()
);

export const userAuthenticated = createAction(
  ActionTypes.AUTHENTICATED,
  props<{ userId: string; userOfficeId: string }>()
);

export const refreshAccessToken = createAction(ActionTypes.RefreshAccessToken);

export const refreshAccessTokenSuccess = createAction(
  ActionTypes.RefreshAccessTokenSuccess,
  props<{ accessToken: string; refreshToken: string; expiresIn: number }>()
);

export const refreshAccessTokenFailure = createAction(
  ActionTypes.RefreshAccessTokenFailure,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>()
);

export const logout = createAction(ActionTypes.LOGOUT);

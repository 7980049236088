import { createAnalyticsEvent } from "@kells/apis/analytics";
import { CommonAttributable } from "./base-attributes";

enum EventTypes {
  OpenReportPage = "Open Report Page",
  XrayReportDownload = "X-Ray Report download",
  PdfReportDownload = "PDF Report download",
  UnavailableBrowserReportDownload = "Unavailable Browser Report download",
  XRaysDownload = "X-Rays download",
  XRayView = "X-Ray view",
  CariesDiagramToothClick = "caries_diagram_tooth_click",
  CariesDiagramXRayTabClick = "caries_diagram_x-ray_tab_click",
  CariesDiagramIntraoralPhotoTabClick = "caries_diagram_intraoral-photo_tab_click",
  CariesSectionQAndAClick = "caries_section_q_a_click",
  BoneLossSectionQAndAClick = "bone_loss_section_q_a_click",
  ScheduleAppointmentBtnClick = "schedule_appointment_btn_click",
  // Demo
  DemoClickViewLink = "demo_click_view_demo_link",
  DemoXRayView = "demo_x_ray_view",
  DemoGetReportClick = "demo_get_report_click",
  DemoCariesDiagramXRayTabClick = "demo_caries_diagram_x-ray_tab_click",
  DemoCariesDiagramIntraoralPhotoTabClick = "demo_caries_diagram_intraoral-photo_tab_click",
  DemoCariesDiagramToothClick = "demo_caries_diagram_tooth_click",
  DemoScheduleAppointmentBtnClick = "demo_schedule_appointment_btn_click",
}

export const openReportPage = createAnalyticsEvent<CommonAttributable>(
  EventTypes.OpenReportPage
);

export const downloadReport = createAnalyticsEvent<CommonAttributable>(
  EventTypes.PdfReportDownload
);

export const downloadXrayReport = createAnalyticsEvent<CommonAttributable>(
  EventTypes.XrayReportDownload
);

export const downloadUnavailableBrowserReport = createAnalyticsEvent(
  EventTypes.UnavailableBrowserReportDownload
);

export const downloadXrays = createAnalyticsEvent<CommonAttributable>(
  EventTypes.XRaysDownload
);

export const xrayView = createAnalyticsEvent<CommonAttributable>(
  EventTypes.XRayView
);

export const cariesToothClick = createAnalyticsEvent<CommonAttributable>(
  EventTypes.CariesDiagramToothClick
);

export const cariesXRayTabClick = createAnalyticsEvent<CommonAttributable>(
  EventTypes.CariesDiagramXRayTabClick
);

export const cariesIntraoralPhotoTabClick = createAnalyticsEvent<CommonAttributable>(
  EventTypes.CariesDiagramIntraoralPhotoTabClick
);

export const DemoXRayView = createAnalyticsEvent(EventTypes.DemoXRayView);

export const DemoClickViewLink = createAnalyticsEvent(
  EventTypes.DemoClickViewLink
);

export const DemoGetReportClick = createAnalyticsEvent(
  EventTypes.DemoGetReportClick
);

export const DemoScheduleAppointmentClick = createAnalyticsEvent(
  EventTypes.DemoScheduleAppointmentBtnClick
);

export const DemoCariesToothClick = createAnalyticsEvent(
  EventTypes.DemoCariesDiagramToothClick
);

export const DemoCariesXRayTabClick = createAnalyticsEvent(
  EventTypes.DemoCariesDiagramXRayTabClick
);

export const DemoCariesIntraoralPhotoTabClick = createAnalyticsEvent(
  EventTypes.DemoCariesDiagramIntraoralPhotoTabClick
);

interface QAndAData extends CommonAttributable {
  panelNumber: number;
}

export const qaCariesSection = createAnalyticsEvent<QAndAData>(
  EventTypes.CariesSectionQAndAClick
);

export const qaBoneLossSection = createAnalyticsEvent<QAndAData>(
  EventTypes.BoneLossSectionQAndAClick
);

export const scheduleAppointmentClick = createAnalyticsEvent<CommonAttributable>(
  EventTypes.ScheduleAppointmentBtnClick
);

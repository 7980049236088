import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  ReviewJobState,
  selectAllReviewJobs as selectAll,
  featureKey,
} from "./review-job.reducer";

export const selectReviewJobState = createFeatureSelector<ReviewJobState>(
  featureKey
);

export const selectAllReviewJobs = createSelector(
  selectReviewJobState,
  selectAll
);

export const selectIsGeneratingReport = createSelector(
  selectReviewJobState,
  (state: ReviewJobState) => state.isGeneratingReport
);

export const selectReviewJob = createSelector(
  selectReviewJobState,
  (state: ReviewJobState, props: { patientId: string; sessionId: string }) => {
    const selected = Object.values(state.entities).filter(
      (job) => job?.patientId === props.patientId && job.id === props.sessionId
    );

    if (!selected || selected.length === 0) return;
    return selected[0];
  }
);

<div class="modal-wrapper">
  <mat-dialog-content>
    <div>
      <i [class]="iconClassname"></i>
    </div>
    <div class="modal-title">{{ title }}</div>
    <div class="modal-description">
      {{ description }}
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button class="modal-btn connect-btn" (click)="closeDialog()">
      {{ closeButtonTitle }}
    </button>
  </mat-dialog-actions>
</div>

<div [ngClass]="className">
  <svg #svg preserveAspectRatio="xMinYMid meet" viewBox="0 0 28 70">
    <!-- <defs #defs>
    <g id="image">
      <image #xray crossorigin="anonymous" [attr.xlink:href]="xrayImageUrl | async"></image>
    </g>
  </defs> -->
    <filter
      id="bevelShadow_1"
      x="-20%"
      y="-20%"
      width="140%"
      height="140%"
      name="AI_BevelShadow_1"
    >
      <feGaussianBlur result="blur" stdDeviation="3" in="SourceAlpha" />
      <feOffset result="offsetBlur" dx="2" dy="2" in="blur" />
      <feSpecularLighting
        result="specOut"
        specularExponent="3"
        surfaceScale="5"
        in="blur"
      >
        <fePointLight x="-5000" y="-10000" z="-20000" />
      </feSpecularLighting>
      <feComposite
        result="specOut"
        operator="in"
        in="specOut"
        in2="SourceAlpha"
      />
      <feComposite
        result="litPaint"
        k2="1"
        k3="1"
        operator="arithmetic"
        in="SourceGraphic"
        in2="specOut"
      />
      <feMerge>
        <feMergeNode in="offsetBlur" />
        <feMergeNode in="litPaint" />
      </feMerge>
    </filter>

    <filter id="filter">
      <feOffset in="SourceAlpha" dx="20" dy="20"></feOffset>

      <feGaussianBlur stdDeviation="10" result="DROP"></feGaussianBlur>
      <feFlood flood-color="#000" result="COLOR"></feFlood>
      <feComposite
        in="DROP"
        in2="COLOR"
        operator="in"
        result="SHADOW1"
      ></feComposite>
      <feComponentTransfer in="SHADOW1" result="SHADOW">
        <feFuncA type="table" tableValues="0 0.5"></feFuncA>
      </feComponentTransfer>

      <!-- You can use ANY two results as inputs to any primitive, regardless
    of their order in the DOM.-->
      <feMerge>
        <feMergeNode in="SHADOW1"></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
    </filter>

    <filter id="red">
      <feColorMatrix
        type="matrix"
        values="1   0   0   0   0
              0   0   0   0   0
              0   0   0   0   0
              0   0   0   1   0 "
      />
    </filter>
    <!-- <filter id="blurlayer2" width="120%" height="100%">
            <feColorMatrix
    type="matrix"
    values=".7    0   0   0   0
            -0.2   .7   0   0   0
            -.3    0   .7   0   0
            0    0   0    1   0 " />

            <feGaussianBlur  stdDeviation="4" result="blur"/>
            <feImage id="feimage" [attr.xlink:href]="image.id" x="0" y="0"  height="100%" result="mask" />
            <feComposite in2="imgmask" in="blur" in3="rect2" operator="in" result="comp" />

            <feMerge result="merge">
              <feMergeNode in="SourceGraphic" />
              <feMergeNode in="comp" />
              <feMergeNode in="comp2" />
            </feMerge>

          </filter> -->

    <!-- <g>
    <image #xray crossorigin="anonymous" [attr.xlink:href]="xrayImageUrl | async"></image>
  </g> -->

    <!-- <use #xray [attr.href]="xrayImageUrl | async" x="0" y="0" width="100%" height="100%"></use> -->

    <foreignObject x="0" y="0" width="100%" height="100%">
      <img #xray />
    </foreignObject>

    <g #cavityFindings>
      <ng-container *ngIf="showCaries">
        <ng-container *ngLet="cariesAdvanced$ | async as cariesAdvanced">
          <ng-container *ngFor="let aCry of cariesAdvanced">
            <ng-container
              *ngIf="aCry.tooth == this.filterTooth || !this.filterTooth"
            >
              <g
                filter="url(#bevelShadow_1)"
                [tippy]
                [tippyOptions]="{
                  trigger: showFindingDescription ? 'mouseover' : '',
                  theme: 'light',
                  arrow: true,
                  allowHTML: false,
                  content:
                    'Advanced cavity, tooth \n' + getToothName(aCry.tooth)
                }"
              >
                <rect
                  [attr.x]="aCry?.box[0] - 1"
                  [attr.y]="aCry?.box[1] - 1"
                  [attr.width]="aCry?.box[2] + 2"
                  [attr.height]="aCry?.box[3] + 2"
                  shape-rendering="auto"
                  vector-effect="non-scaling-stroke"
                  stroke-position="inside"
                  stroke="#941B1C"
                  stroke-width="3"
                  fill="none"
                  rx="6"
                ></rect>
                <rect
                  [attr.x]="aCry?.box[0]"
                  [attr.y]="aCry?.box[1]"
                  [attr.width]="aCry?.box[2]"
                  shape-rendering="auto"
                  vector-effect="non-scaling-stroke"
                  fill="transparent"
                  [attr.height]="aCry?.box[3]"
                  stroke="#F84447"
                  stroke-width="2"
                  rx="6"
                ></rect>
              </g>
            </ng-container>
          </ng-container>
        </ng-container>

        <!-- <use #xray [attr.href]="xrayImageUrl | async" x="0" y="0" width="100%" mask="advancedMask" height="100%"></use> -->

        <ng-container *ngLet="cariesModerate$ | async as cariesModerate">
          <ng-container *ngFor="let aCry of cariesModerate">
            <ng-container
              *ngIf="aCry.tooth == this.filterTooth || !this.filterTooth"
            >
              <g
                filter="url(#bevelShadow_1)"
                [tippy]
                [tippyOptions]="{
                  trigger: showFindingDescription ? 'mouseenter' : '',
                  theme: 'light',
                  arrow: true,
                  allowHTML: false,
                  content:
                    'Moderate cavity, tooth \n' + getToothName(aCry.tooth)
                }"
              >
                <rect
                  [attr.x]="aCry?.box[0] - 1"
                  [attr.y]="aCry?.box[1] - 1"
                  [attr.width]="aCry?.box[2] + 2"
                  [attr.height]="aCry?.box[3] + 2"
                  shape-rendering="auto"
                  vector-effect="non-scaling-stroke"
                  stroke-position="inside"
                  stroke="#fde047"
                  stroke-width="3"
                  fill="none"
                  rx="6"
                ></rect>
                <rect
                  [attr.x]="aCry?.box[0]"
                  [attr.y]="aCry?.box[1]"
                  [attr.width]="aCry?.box[2]"
                  shape-rendering="auto"
                  vector-effect="non-scaling-stroke"
                  fill="transparent"
                  [attr.height]="aCry?.box[3]"
                  stroke="#fde047"
                  stroke-width="2"
                  rx="6"
                ></rect>
              </g>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngLet="cariesInitial$ | async as cariesInitial">
          <ng-container *ngFor="let aCry of cariesInitial">
            <ng-container
              *ngIf="aCry.tooth == this.filterTooth || !this.filterTooth"
            >
              <g
                filter="url(#bevelShadow_1)"
                [tippy]
                [tippyOptions]="{
                  trigger: showFindingDescription ? 'mouseenter' : '',
                  theme: 'light',
                  arrow: true,
                  allowHTML: false,
                  content: 'Initial cavity, tooth ' + getToothName(aCry.tooth)
                }"
              >
                <rect
                  [attr.x]="aCry?.box[0] - 1"
                  [attr.y]="aCry?.box[1] - 1"
                  [attr.width]="aCry?.box[2] + 2"
                  [attr.height]="aCry?.box[3] + 2"
                  shape-rendering="auto"
                  vector-effect="non-scaling-stroke"
                  stroke-position="inside"
                  stroke="#EBF1DE"
                  stroke-width="3"
                  fill="none"
                  rx="6"
                ></rect>
                <rect
                  [attr.x]="aCry?.box[0]"
                  [attr.y]="aCry?.box[1]"
                  [attr.width]="aCry?.box[2]"
                  shape-rendering="auto"
                  vector-effect="non-scaling-stroke"
                  [attr.height]="aCry?.box[3]"
                  stroke="#DBD4DC"
                  stroke-width="2"
                  fill="transparent"
                  rx="6"
                ></rect>
              </g>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </g>

    <ng-container *ngIf="showBoneloss">
      <ng-container *ngLet="boneLoss$ | async as boneLoss">
        <ng-container *ngFor="let bloss of boneLoss">
          <g
            filter="url(#bevelShadow_1)"
            [tippy]
            [tippyOptions]="{
              trigger: showFindingDescription ? 'mouseenter' : '',
              theme: 'light',
              arrow: true,
              allowHTML: false,
              content:
                'Site of bone loss risk, tooth ' + getToothName(bloss.tooth)
            }"
            fill="#EBF1DE"
            stroke="#EBF1DE"
          >
            <line
              *ngIf="bloss.bone_loss_attributes.severity >= 3"
              [attr.x1]="bloss.bone_loss_attributes.cej_x"
              [attr.y1]="bloss.bone_loss_attributes.cej_y"
              [attr.x2]="bloss.bone_loss_attributes.ac_x"
              [attr.y2]="bloss.bone_loss_attributes.ac_y"
              [ngStyle]="
                bloss.bone_loss_attributes.severity >= 3 &&
                bloss.bone_loss_attributes.severity < 5
                  ? { stroke: '#f5c465' }
                  : bloss.bone_loss_attributes.severity >= 5
                  ? { stroke: '#941B1C' }
                  : {}
              "
              shape-rendering="auto"
              vector-effect="non-scaling-stroke"
              stroke-position="inside"
              stroke-width="2"
            />
            <rect
              *ngIf="bloss.bone_loss_attributes.severity >= 3"
              [attr.x]="bloss.bone_loss_attributes.cej_x - 4"
              [attr.y]="bloss.bone_loss_attributes.cej_y - 4"
              [attr.width]="8"
              [attr.height]="8"
              shape-rendering="auto"
              vector-effect="non-scaling-stroke"
              stroke-position="inside"
              stroke-width="3"
              rx="2"
            ></rect>
            <ellipse
              *ngIf="bloss.bone_loss_attributes.severity >= 3"
              [attr.cx]="bloss.bone_loss_attributes.ac_x"
              [attr.cy]="bloss.bone_loss_attributes.ac_y"
              rx="4"
              ry="4"
              shape-rendering="auto"
              vector-effect="non-scaling-stroke"
              stroke-position="inside"
              stroke-width="3"
            />
          </g>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="showFracture">
      <ng-container *ngLet="fracture$ | async as fracture">
        <ng-container *ngFor="let fr of fracture">
          <ng-container
            *ngIf="fr.tooth == this.filterTooth || !this.filterTooth"
          >
            <g
              filter="url(#bevelShadow_1)"
              [tippy]
              [tippyOptions]="{
                trigger: showFindingDescription ? 'mouseover' : '',
                theme: 'light',
                arrow: true,
                allowHTML: false,
                content: 'Fracture, tooth \n' + getToothName(fr.tooth)
              }"
            >
              <rect
                [attr.x]="fr?.box[0] - 1"
                [attr.y]="fr?.box[1] - 1"
                [attr.width]="fr?.box[2] + 2"
                [attr.height]="fr?.box[3] + 2"
                shape-rendering="auto"
                vector-effect="non-scaling-stroke"
                stroke-position="inside"
                stroke="#941B1C"
                stroke-width="3"
                fill="none"
                rx="6"
              ></rect>
              <rect
                [attr.x]="fr?.box[0]"
                [attr.y]="fr?.box[1]"
                [attr.width]="fr?.box[2]"
                shape-rendering="auto"
                vector-effect="non-scaling-stroke"
                fill="transparent"
                [attr.height]="fr?.box[3]"
                stroke="#F84447"
                stroke-width="2"
                rx="6"
              ></rect>
            </g>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="showPlaque">
      <ng-container *ngLet="plaque$ | async as plaque">
        <ng-container *ngFor="let pl of plaque">
          <ng-container
            *ngIf="pl.tooth == this.filterTooth || !this.filterTooth"
          >
            <g
              filter="url(#bevelShadow_1)"
              [tippy]
              [tippyOptions]="{
                trigger: showFindingDescription ? 'mouseover' : '',
                theme: 'light',
                arrow: true,
                allowHTML: false,
                content: 'Plaque, tooth \n' + getToothName(pl.tooth)
              }"
            >
              <rect
                [attr.x]="pl?.box[0] - 1"
                [attr.y]="pl?.box[1] - 1"
                [attr.width]="pl?.box[2] + 2"
                [attr.height]="pl?.box[3] + 2"
                shape-rendering="auto"
                vector-effect="non-scaling-stroke"
                stroke-position="inside"
                stroke="#941B1C"
                stroke-width="3"
                fill="none"
                rx="6"
              ></rect>
              <rect
                [attr.x]="pl?.box[0]"
                [attr.y]="pl?.box[1]"
                [attr.width]="pl?.box[2]"
                shape-rendering="auto"
                vector-effect="non-scaling-stroke"
                fill="transparent"
                [attr.height]="pl?.box[3]"
                stroke="#F84447"
                stroke-width="2"
                rx="6"
              ></rect>
            </g>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="showGumRecession">
      <ng-container *ngLet="gumRecession$ | async as gumRecession">
        <ng-container *ngFor="let gr of gumRecession">
          <ng-container
            *ngIf="gr.tooth == this.filterTooth || !this.filterTooth"
          >
            <g
              filter="url(#bevelShadow_1)"
              [tippy]
              [tippyOptions]="{
                trigger: showFindingDescription ? 'mouseover' : '',
                theme: 'light',
                arrow: true,
                allowHTML: false,
                content: 'Gum Recession, tooth \n' + getToothName(gr.tooth)
              }"
            >
              <rect
                [attr.x]="gr?.box[0] - 1"
                [attr.y]="gr?.box[1] - 1"
                [attr.width]="gr?.box[2] + 2"
                [attr.height]="gr?.box[3] + 2"
                shape-rendering="auto"
                vector-effect="non-scaling-stroke"
                stroke-position="inside"
                stroke="#941B1C"
                stroke-width="3"
                fill="none"
                rx="6"
              ></rect>
              <rect
                [attr.x]="gr?.box[0]"
                [attr.y]="gr?.box[1]"
                [attr.width]="gr?.box[2]"
                shape-rendering="auto"
                vector-effect="non-scaling-stroke"
                fill="transparent"
                [attr.height]="gr?.box[3]"
                stroke="#F84447"
                stroke-width="2"
                rx="6"
              ></rect>
            </g>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="showGumInflammation">
      <ng-container *ngLet="gumInflammation$ | async as gumInflammation">
        <ng-container *ngFor="let gi of gumInflammation">
          <ng-container
            *ngIf="gi.tooth == this.filterTooth || !this.filterTooth"
          >
            <g
              filter="url(#bevelShadow_1)"
              [tippy]
              [tippyOptions]="{
                trigger: showFindingDescription ? 'mouseover' : '',
                theme: 'light',
                arrow: true,
                allowHTML: false,
                content: 'Gum Inflammation, tooth \n' + getToothName(gi.tooth)
              }"
            >
              <rect
                [attr.x]="gi?.box[0] - 1"
                [attr.y]="gi?.box[1] - 1"
                [attr.width]="gi?.box[2] + 2"
                [attr.height]="gi?.box[3] + 2"
                shape-rendering="auto"
                vector-effect="non-scaling-stroke"
                stroke-position="inside"
                stroke="#941B1C"
                stroke-width="3"
                fill="none"
                rx="6"
              ></rect>
              <rect
                [attr.x]="gi?.box[0]"
                [attr.y]="gi?.box[1]"
                [attr.width]="gi?.box[2]"
                shape-rendering="auto"
                vector-effect="non-scaling-stroke"
                fill="transparent"
                [attr.height]="gi?.box[3]"
                stroke="#F84447"
                stroke-width="2"
                rx="6"
              ></rect>
            </g>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="showMissingTooth">
      <ng-container *ngLet="missingTooth$ | async as missingTooth">
        <ng-container *ngFor="let mt of missingTooth">
          <ng-container
            *ngIf="mt.tooth == this.filterTooth || !this.filterTooth"
          >
            <g
              filter="url(#bevelShadow_1)"
              [tippy]
              [tippyOptions]="{
                trigger: showFindingDescription ? 'mouseover' : '',
                theme: 'light',
                arrow: true,
                allowHTML: false,
                content: 'Missing Tooth, tooth \n' + getToothName(mt.tooth)
              }"
            >
              <rect
                [attr.x]="mt?.box[0] - 1"
                [attr.y]="mt?.box[1] - 1"
                [attr.width]="mt?.box[2] + 2"
                [attr.height]="mt?.box[3] + 2"
                shape-rendering="auto"
                vector-effect="non-scaling-stroke"
                stroke-position="inside"
                stroke="#941B1C"
                stroke-width="3"
                fill="none"
                rx="6"
              ></rect>
              <rect
                [attr.x]="mt?.box[0]"
                [attr.y]="mt?.box[1]"
                [attr.width]="mt?.box[2]"
                shape-rendering="auto"
                vector-effect="non-scaling-stroke"
                fill="transparent"
                [attr.height]="mt?.box[3]"
                stroke="#F84447"
                stroke-width="2"
                rx="6"
              ></rect>
            </g>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <!-- <ng-container *ngIf="tooth && tooth.boneloss.length > 0">
        <ng-container *ngFor="let aCry of tooth.initial">
          <rect [attr.x]="aCry?.box[0]" [attr.y]="aCry?.box[1]" [attr.width]="aCry?.box[2]"
          vector-effect="non-scaling-stroke" shape-rendering="crispedges"
          [attr.height]="aCry?.box[3]" stroke="#9ca8d1" stroke-width="6" fill="#00000000"></rect>
        </ng-container>
    </ng-container> -->
    <!-- <g  y="40" x="0" [attr.width]="xrayWidth"  [attr.height]="xrayHeight"  ><text>{{findings | json}}</text></g> -->
  </svg>
</div>

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export type Status = "success" | "error";

export interface StatusModalData {
  status: Status;
  title: string;
  description: string;
  closeButtonTitle: string;
}

@Component({
  selector: "koa-status-modal",
  templateUrl: "./status-modal.component.html",
  styleUrls: ["./status-modal.component.scss"],
})
export class StatusModalComponent {
  public status: Status;
  public title: string;
  public description: string;
  public iconClassname: string;
  public closeButtonTitle: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StatusModalData,
    public readonly dialogRef: MatDialogRef<StatusModalComponent>
  ) {
    this.status = data.status;

    if (this.status === "success") {
      this.title = data.title ?? "Success!";
      this.description = data.description;
      this.iconClassname = "fa-solid fa-circle-check";
      this.closeButtonTitle = data.closeButtonTitle ?? "Continue";
    } else {
      this.title = data.title ?? "Oops something went wrong!";
      this.description = data.description;

      this.iconClassname = "fa-solid fa-circle-xmark";
      this.closeButtonTitle = data.closeButtonTitle ?? "Close";
    }
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { SubSink } from "subsink";
import { RecommendedProductTabs } from "../../shared/enums/recommended-product.enum";
import { RecommendedProduct } from "../../shared/interfaces/recommended-product.interfaces";
import { recommendedProductsByCategory } from "../../shared/constants/recommended-products-by-category.constants";

@Component({
  selector: "kpr-product-recommendation-main",
  templateUrl: "./product-recommendation-main.component.html",
  styleUrls: ["./product-recommendation-main.component.scss"],
})
export class ProductRecommendationMainComponent implements OnInit, OnDestroy {
  tab: RecommendedProductTabs = RecommendedProductTabs.Toothbrush;

  public recommendedProductsByCategory = recommendedProductsByCategory;
  public displayedRecommendedItems: RecommendedProduct[];
  public tabs = Object.values(RecommendedProductTabs).filter(
    (tab) => recommendedProductsByCategory[tab].length > 0
  );

  private _subs = new SubSink();

  ngOnInit(): void {
    this.displayedRecommendedItems = this.recommendedProductsByCategory?.[
      this.tab
    ];
  }

  tabChange(tab: RecommendedProductTabs) {
    this.tab = tab;
    this.displayedRecommendedItems = this.recommendedProductsByCategory?.[
      this.tab
    ];
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }
}

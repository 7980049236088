import { RecommendedProductTabs } from "../enums/recommended-product.enum";
import { RecommendedProduct } from "../interfaces/recommended-product.interfaces";

export const recommendedProductsByCategory: Record<
  RecommendedProductTabs,
  RecommendedProduct[]
> = {
  [RecommendedProductTabs.Toothbrush]: [
    {
      name: "Smart brush",
      brand: "Oral B",
      retailLink: "https://a.co/d/74Up9Kw",
      amazonAffiliateLink: "https://amzn.to/3AokZHQ",
      image: "assets/recommended-products/electric-toothbrush-oral-b.jpg",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Gum Inflammation / Gingivitis",
        "Periodontitis / bone loss",
        "Sensitive Teeth",
      ],
    },
    {
      name: "Ultra Next Generation Smart Sonic Electric Toothbrush",
      brand: "Quip",
      retailLink:
        "https://www.getquip.com/products/eclipse-quip-ultra-next-generation-smart-sonic-electric-toothbrush",
      image:
        "assets/recommended-products/ultra-next-generation-smart-sonic-electric-toothbrush.webp",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Gum Inflammation / Gingivitis",
        "Periodontitis / bone loss",
      ],
    },
    {
      name: "Sonic Pro",
      brand: "Autobrush",
      retailLink:
        "https://tryautobrush.com/products/autobrush-pro?variant=40013583188015",
      image: "assets/recommended-products/sonic-pro.webp",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Gum Inflammation / Gingivitis",
        "Periodontitis / bone loss",
      ],
    },
    {
      name: "Pro Sonic Toothbrush",
      brand: "Burst",
      retailLink:
        "https://burstoralcare.com/collections/all/products/pro-sonic-toothbrush?variant=43900689416347",
      image: "assets/recommended-products/pro-sonic-toothbrush.webp",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Gum Inflammation / Gingivitis",
        "Periodontitis / bone loss",
      ],
    },
    // {
    //   name: "Curve Sonic Toothbrush",
    //   brand: "Burst",
    //   retailLink:
    //     "https://burstoralcare.com/collections/all/products/curve-sonic-toothbrush?variant=43946118643867",
    //   image: "assets/recommended-products/curve-sonic-toothbrush.webp",
    // },
    // {
    //   name: "Kids electric toothbrush",
    //   brand: "Burst",
    //   retailLink: "https://a.co/d/gCqrK42",
    //   amazonAffiliateLink: "https://amzn.to/46RugUE",
    //   brandAffiliateLink: "https://ca.burstoralcare.com/ambassador",
    //   discount: {
    //     amount: 20,
    //   },
    //   image: "assets/recommended-products/burst-kids.jpg",
    //   subCategory: ["Pediatric dental health"],
    // },
  ],
  [RecommendedProductTabs.Toothpaste]: [
    {
      name: "Fluoridated toothpaste",
      brand: "Biotène",
      retailLink: "https://a.co/d/eZu40BE",
      amazonAffiliateLink: "https://amzn.to/4dtA9dw",
      image: "assets/recommended-products/fluoridated-toothpaste.jpg",
      subCategory: ["General hygiene", "Cavity", "Xerostomia / dry mouth"],
    },
    {
      name: "Gum detoxify toothpaste",
      brand: "Crest",
      retailLink: "https://a.co/d/bjHFTiw",
      amazonAffiliateLink: "https://amzn.to/3yHQQT8",
      image: "assets/recommended-products/crest-gum-detoxity.jpg",
      subCategory: ["Gingivitis", "Periodontitis / bone loss"],
    },
    {
      name: "Sensitive toothpaste",
      brand: "Sensodyne",
      retailLink: "https://a.co/d/9WYVMpm",
      amazonAffiliateLink: "https://amzn.to/4csH6u2",
      image: "assets/recommended-products/sensodyne.jpg",
      subCategory: ["Sensitive Teeth"],
    },
    // {
    //   name: "Children’s anti-cavity toothpaste",
    //   brand: "Orajel",
    //   retailLink: "https://a.co/d/d9DdX5k",
    //   amazonAffiliateLink: "https://amzn.to/3STWgkR",
    //   image: "assets/recommended-products/orajel-kids-toothpaste.jpg",
    //   subCategory: ["Pediatric dental health"],
    // },
  ],
  [RecommendedProductTabs.Floss]: [
    {
      name: "Cocofloss",
      brand: "Cocolab",
      retailLink: "https://amzn.to/4aOIdE8",
      amazonAffiliateLink: "https://amzn.to/3X6umof",
      brandAffiliateLink: "https://shrsl.com/4tuf1",
      image: "assets/recommended-products/cocofloss.jpg",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Gum Inflammation / Gingivitis",
        "Periodontitis / bone loss",
        "Sensitive Teeth",
      ],
    },
    {
      name: "Expanding floss",
      brand: "Burst",
      retailLink: "https://a.co/d/10qkbjM",
      amazonAffiliateLink: "https://a.co/d/7ExVP1x",
      image: "assets/recommended-products/burst-expanding-floss.jpg",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Gum Inflammation / Gingivitis",
        "Periodontitis / bone loss",
        "Sensitive Teeth",
      ],
    },
    {
      name: "Aquarius Water Flosser",
      brand: "Waterpik",
      retailLink: "https://a.co/d/atQRd7D",
      image: "assets/recommended-products/waterpik-water-flosser.jpg",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Gum Inflammation / Gingivitis",
        "Periodontitis / bone loss",
        "Sensitive Teeth",
      ],
    },
    {
      name: "Rechargeable Electric Flosser",
      brand: "Slate",
      retailLink:
        "https://slateflosser.com/products/slate-rechargeable-electric-flosser",
      brandAffiliateLink: "https://slateflosser.com/SFRXQDCF",
      discount: {
        amount: 10,
      },
      image: "assets/recommended-products/slate.webp",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Gum Inflammation / Gingivitis",
        "Periodontitis / bone loss",
        "Sensitive Teeth",
      ],
    },
    {
      name: "E-flosser",
      brand: "Flaus",
      retailLink: "https://goflaus.com/",
      image: "assets/recommended-products/flaus.webp",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Gum Inflammation / Gingivitis",
        "Periodontitis / bone loss",
        "Sensitive Teeth",
      ],
    },
    // {
    //   name: "Dental Floss Picks for Kids",
    //   brand: "GuruNanda",
    //   retailLink: "https://a.co/d/2GoHWjR",
    //   amazonAffiliateLink: "https://amzn.to/3M856HX",
    //   image: "assets/recommended-products/kids-floss-picks.jpg",
    //   subCategory: ["Pediatric dental health"],
    // },
  ],
  [RecommendedProductTabs.Mouthrinse]: [
    {
      name: "Total Care",
      brand: "Listerine",
      retailLink: "https://a.co/d/2WHHrAJ",
      amazonAffiliateLink: "https://amzn.to/3AoUphD",
      image: "assets/recommended-products/listerine-total-care.jpg",
      subCategory: [
        "General hygiene",
        "Cavity",
        "Periodontitis / bone loss",
        "Sensitive Teeth",
        "Halitosis / bad breath",
      ],
    },
    {
      name: "Gum care",
      brand: "Listerine",
      retailLink: "https://a.co/d/cLZOaxg",
      amazonAffiliateLink: "https://amzn.to/4crdmxr",
      image: "assets/recommended-products/listerine-gum-care.jpg",
      subCategory: ["Gum Inflammation / Gingivitis"],
    },
    {
      name: "Dry Mouth rinse",
      brand: "Biotène",
      retailLink: "https://a.co/d/eJYT4uf",
      image: "assets/recommended-products/biotene-dry-mouth-rinse.jpg",
      subCategory: ["Xerostomia / dry mouth"],
    },
    {
      name: "Peroxyl and warm salt water rinse",
      brand: "Colgate",
      retailLink: "https://a.co/d/iNsB2UZ",
      image: "assets/recommended-products/colgate-peroxyl.jpg",
      subCategory: ["Canker sores / Aphthous Ulcers"],
    },
    // {
    //   name: "Kid’s ACT fluoride rinse",
    //   brand: "ACT",
    //   retailLink: "https://a.co/d/hVq7ZF0",
    //   image: "assets/recommended-products/act-kids-protect.jpg",
    //   subCategory: ["Pediatric dental health"],
    // },
  ],
  [RecommendedProductTabs.Gel]: [
    // {
    //   name: "Oral Balance Gel",
    //   brand: "Biotène",
    //   amazonAffiliateLink: "https://a.co/d/eaMJgml",
    //   subCategory: ["Xerostomia / dry mouth"],
    //   image: "assets/recommended-products/biotene-dry-mouth.jpg",
    // },
  ],
  [RecommendedProductTabs.TongueScraper]: [
    {
      name: "Tongue scraper",
      brand: "Hokin",
      retailLink: "https://a.co/d/iAp9Q3a",
      image: "assets/recommended-products/tongue-scraper.jpg",
      subCategory: ["Halitosis / Bad breath"],
    },
  ],
  [RecommendedProductTabs.NightGuard]: [
    {
      name: "Night guard",
      brand: "Smile Brilliant",
      retailLink:
        "https://www.smilebrilliant.com/product/night-guards/?coupon=kells20&ref=8902&campaign=NightGuards",
      brandAffiliateLink:
        "https://www.smilebrilliant.com/product/night-guards/?coupon=kells20&ref=8902",
      discount: {
        amount: 20,
      },
      image: "assets/recommended-products/smile-brilliant-night-guard.jpeg",
      subCategory: ["Bruxism / Grinding"],
    },
  ],
  [RecommendedProductTabs.TeethWhitening]: [
    {
      name: "Whitening trays",
      brand: "Smile Brilliant",
      retailLink:
        "https://www.smilebrilliant.com/product/teeth-whitening-trays/?coupon=kells20&ref=8902&campaign=WhiteningTray",
      brandAffiliateLink:
        "https://www.smilebrilliant.com/product/teeth-whitening-trays/?coupon=kells20&ref=8902&campaign=WhiteningTray",
      discount: {
        amount: 20,
      },
      image: "assets/recommended-products/smile-brilliant-whitening-trays.jpeg",
      subCategory: ["Whitening"],
    },
    {
      name: "Prefilled Whitening Trays 7 pack",
      brand: "Burst",
      retailLink:
        "https://burstoralcare.com/collections/all/products/prefilled-whitening-trays?variant=43953994039451",
      image: "assets/recommended-products/prefilled-whitening-trays.webp",
      subCategory: ["Whitening Trays"],
    },
    // {
    //   name: "Teeth Whitening Breath Strips 3-pack",
    //   brand: "Burst",
    //   retailLink:
    //     "https://burstoralcare.com/collections/all/products/teeth-whitening-breath-strips-hp-3-pack?variant=45714667176091",
    //   image: "assets/recommended-products/teeth-whitening-breath-strips.webp",
    //   subCategory: ["Whitening Strips"],
    // },
  ],
  [RecommendedProductTabs.Preventative]: [
    {
      name: "Oral Health Test",
      brand: "Bristle",
      retailLink: "https://www.bristlehealth.com/",
      amazonAffiliateLink: "https://bristlehealth.com/?ref=zmfkngu",
      brandAffiliateLink:
        "https://www.bristlehealth.com/products/oral-health-test?ref=zmfkngu",
      discount: {
        amount: 10,
        type: "coupon",
      },
      image: "assets/recommended-products/bristle-oral-health-test.jpeg",
      subCategory: ["Preventative"],
    },
    {
      name: "Oral Health Solutions",
      brand: "Viome",
      retailLink: "https://www.viome.com/products/oral-health-solutions",
      image: "assets/recommended-products/viome.jpeg",
    },
    {
      name: "Plaque Highlighters",
      brand: "Smile Brilliant",
      brandAffiliateLink:
        "https://www.smilebrilliant.com/product/plaque-highlighters-adults/?coupon=kells20&ref=8902",
      discount: {
        amount: 20,
      },
      image: "assets/recommended-products/plaque-highlighters.png",
    },
  ],
  [RecommendedProductTabs.Supplements]: [
    // {
    //   name: "In general",
    //   brand: "Thorne",
    //   brandAffiliateLink: "https://thorne.com/",
    //   image: "assets/recommended-products/thorne.png",
    //   subCategory: ["General wellness"],
    // },
    {
      name: "Lysine",
      brand: "Thorne",
      brandAffiliateLink: "https://s.thorne.com/zvreV",
      image: "assets/recommended-products/lysine-throne.png",
      subCategory: [
        "Immune function",
        "Bone health",
        "Skin integrity",
        "Encourages wound healing",
      ],
    },
    {
      name: "Vitamin C with Flavoniods",
      brand: "Thorne",
      brandAffiliateLink: "https://s.thorne.com/Ndbe6",
      image: "assets/recommended-products/vitamin-c.png",
      subCategory: ["Immune-supporting", "Antioxidant"],
    },
    {
      name: "CoQ10",
      brand: "Thorne",
      brandAffiliateLink: "https://s.thorne.com/Q77Wf",
      image: "assets/recommended-products/coq10.png",
      subCategory: [
        "Promotes optimal heart, brain, and immune function",
        "Antioxidant",
      ],
    },
  ],
};

export enum RecommendedProductTabs {
  Toothbrush = "Toothbrush",
  Toothpaste = "Toothpaste",
  Floss = "Floss",
  Mouthrinse = "Mouthrinse",
  Gel = "Gel",
  TongueScraper = "Tongue Scraper",
  NightGuard = "Night Guard",
  TeethWhitening = "Teeth Whitening",
  Preventative = "Preventative",
  Supplements = "Supplements",
}

import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import {
  Image,
  ImageService,
  ImageType,
  MembershipService,
  OrganizationService,
  Patient,
  PatientEligibility,
  PatientInsurance,
  PatientRecommendation,
  PatientRecommendationRiskLevels,
  PatientService,
  RiskEvaluationApiService,
  TREATMENT_PRIORITY_BY_TYPE,
  Treatment,
  TreatmentPriority,
  TreatmentService,
  TreatmentTypes,
} from "@kells/clinic-one/apis";
import { ReportService } from "@kells/oral-analytics/report";
import { isNullable, isDefined } from "@kells/utils/js";
import {
  distinctUntilDeepValueChanged,
  keepDefined,
  pipeLog,
} from "@kells/utils/observable/observable-operators";
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from "rxjs";
import {
  switchMap,
  map,
  shareReplay,
  startWith,
  filter,
  tap,
  catchError,
  repeat,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  take,
  finalize,
} from "rxjs/operators";
import { ReviewJobService } from "@kells/clinic-one/data-access/review-jobs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { observeProperty } from "@kells/utils/angular";
import { SubSink } from "subsink";
import {
  CariesStageTypes,
  FindingsByTooth,
  FindingsByImage,
  FindingType,
  KellsFinding,
  RenderableFindingBase,
  TOOTH_NAMES,
  PerioData,
} from "@kells/interfaces/finding";
import { flatten, isNil } from "lodash-es";
import { UserService } from "@kells/clinic-one/data-access/users";
import {
  FreeScans,
  KellsPatient,
  CheckoutLinkRequest,
  Product,
  SubscriptionPlan,
} from "@kells/interfaces/patient";
import {
  ReviewJobSurvey,
  SubmitReviewJobSurveyRarams,
} from "libs/clinic-one/data-access/review-jobs/src/lib/models/review-job.model";
import { ConfirmationModalComponent } from "libs/oral-analytics/report/src/lib/components/confirmation-modal/confirmation-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { NavigateToPaymentLink } from "apps/patient-reporting/src/app/shared/models/analytics/home-events";
import { AnalyticsService } from "@kells/apis/analytics";
import { parse } from "date-fns";
import { mapPatientEligibilityResponse } from "apps/patient-reporting/src/app/shared/utils";

const BITEWING_IDS = new Set([1, 2, 3, 4]);

const createRecommendation = (
  overrides?: Partial<PatientRecommendation>
): PatientRecommendation => {
  const recommendationFallback: PatientRecommendation = {
    id: "placeholder",
    patientId: "placeholder",
    createTs: new Date(),
    note: "",
    riskLevel: PatientRecommendationRiskLevels.Undefined,
    riskFactors: [],
    sessionId: "",
  };
  return { ...recommendationFallback, ...overrides };
};

enum PatientSelectionUpdateType {
  Email,
  Id,
}

type UpdatePatientByEmail = {
  type: PatientSelectionUpdateType.Email;
  email: string;
};

type UpdatePatientById = { type: PatientSelectionUpdateType.Id; id: string };

type PatientSelectionUpdate = UpdatePatientByEmail | UpdatePatientById;

export interface CoreDataLoaded {
  report?: PatientRecommendation;
  selectedSession?: string;
  findingsByTooth?: Map<string, FindingsByTooth>;
  findingsByImage?: Map<string, FindingsByImage>;
  status: string;
}

export function calcSeverity(finding: RenderableFindingBase): number {
  const measurment_mm = finding!.bone_loss_attributes!.measurement_mm as number;

  if (measurment_mm < 1) {
    return 0;
  }
  if (measurment_mm < 2) {
    return 1;
  }
  if (measurment_mm <= 3) {
    return 2;
  }
  if (measurment_mm <= 4) {
    return 3;
  }
  if (measurment_mm > 5) {
    return 5;
  }
  return 1;
  // return Number(finding?.bone_loss_attributes?.measurement_mm ?? finding?.bone_loss_attributes?.measurement_pixel ?? 1);
}

export function calcMeasurement(bone_loss_attributes: PerioData): PerioData {
  // const px: number = Math.sqrt(Math.hypot((bone_loss_attributes.ac_x - bone_loss_attributes.cej_x), (bone_loss_attributes.ac_y - bone_loss_attributes.cej_y)))
  // bone_loss_attributes.measurement_pixel = Number(px).toFixed(2);
  // const mm: string = Number(px * 0.26458333).toFixed(2);

  // bone_loss_attributes.measurement_mm = mm;
  return bone_loss_attributes;
}

/**
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class DataAccessService {
  _subs = new SubSink();
  private patientSelectionUpdate$ = new Subject<PatientSelectionUpdate>();

  private updatePatientSelectionByEmail$: Observable<UpdatePatientByEmail> = this.patientSelectionUpdate$.pipe(
    filter(
      (e): e is UpdatePatientByEmail =>
        e.type === PatientSelectionUpdateType.Email
    )
  );

  private updatePatientSelectionById$: Observable<UpdatePatientById> = this.patientSelectionUpdate$.pipe(
    filter(
      (e): e is UpdatePatientById => e.type === PatientSelectionUpdateType.Id
    )
  );

  private loadPatientErredSubject$ = new BehaviorSubject<boolean>(false);

  selectedSessionId: string;
  public selectedSessionId$ = new ReplaySubject<string>();

  selectedSessionDate: string;

  /**
   * `true` if there is no pending patient-related requests, and requesting
   * patient information has failed.
   */
  loadPatientErred$ = this.loadPatientErredSubject$.asObservable();
  patient: Patient;
  selectedPatient$: Observable<Patient> = merge(
    this.updatePatientSelectionByEmail$.pipe(
      tap(() => {
        this.loadPatientErredSubject$.next(false);
      }),
      switchMap(({ email }) => this.patientService.getPatientByEmail(email))
    ),
    this.updatePatientSelectionById$.pipe(
      tap(() => this.loadPatientErredSubject$.next(false)),

      switchMap(({ id }) =>
        this.patientService
          .getPatientById(id)
          .pipe(map((resp) => PatientService.parsePatientResponse(resp)))
      )
    )
  ).pipe(
    keepDefined(),
    tap((patient: Patient) => {
      this.patient = patient;
    }),
    shareReplay(1),
    catchError(() => {
      this.loadPatientErredSubject$.next(true);
      return EMPTY;
    }),
    repeat()
  );

  patientId: string;
  selectedPatientId$ = this.selectedPatient$.pipe(
    map((patient) => {
      this.patientId = patient.id;
      return patient.id;
    })
  );

  selectedPatientHasPremiumPlan$ = this.selectedPatient$.pipe(
    map((patient) => patient.has_premium_plan)
  );

  selectedPatientSubscriptionPlan$ = this.selectedPatientHasPremiumPlan$.pipe(
    map((hasPremiumPlan) =>
      hasPremiumPlan ? SubscriptionPlan.Premium : SubscriptionPlan.Basic
    )
  );

  public readonly dentistInfoByPatientId$ = this.selectedPatientId$.pipe(
    filter((patiendId) => Boolean(patiendId)),
    take(1),
    switchMap((patientId) =>
      this.userService
        .getUserByPatientId(patientId)
        .pipe(catchError(() => of(null)))
    )
  );

  sessionIndexedImages$ = this.selectedPatientId$.pipe(
    switchMap((id) =>
      this.patientService.getSessionDateIndexedImages(id as string)
    ),
    shareReplay(1)
  );

  sessionDates$ = this.sessionIndexedImages$.pipe(
    map((sessionIndexedImages) => Object.keys(sessionIndexedImages)),
    distinctUntilKeyChanged("length"),
    shareReplay(1)
  );

  reviewJobs$ = this.selectedPatientId$.pipe(
    switchMap((id) =>
      this.reviewJobsService.getPatientReviewJobs(id as string)
    ),
    shareReplay(1)
  );

  completedReviewJobs$ = this.reviewJobs$.pipe(
    map((reviewJobs) => reviewJobs.filter((job) => job.completed))
  );

  latestReview$ = this.completedReviewJobs$.pipe(
    map((jobs) => {
      const latestReview = jobs.sort((job1, job2) => {
        const date1 = parse(
          job1.sessionDate.split("T")[0],
          "yyyy-MM-dd",
          new Date()
        );
        const date2 = parse(
          job2.sessionDate.split("T")[0],
          "yyyy-MM-dd",
          new Date()
        );
        return date2.getTime() - date1.getTime();
      })?.[0];
      return latestReview;
    })
  );

  images$ = combineLatest([
    this.sessionIndexedImages$,
    this.selectedSessionId$,
  ]).pipe(
    map(([sessionIndexedImages, selectedSessionId]) => {
      const sessionImages = flatten(Object.values(sessionIndexedImages));

      return sessionImages.filter(
        (image) => image.sessionId === selectedSessionId
      );
    }),
    keepDefined(),
    map((images) => images.map(ImageService.parseImageResponse))
  );

  bitewings$ = this.images$.pipe(
    map((images) => images.filter((g) => BITEWING_IDS.has(g.templateId ?? -1))),
    map((bitewings) =>
      bitewings.sort(
        (bw1, bw2) => (bw1.templateId ?? 100) - (bw2.templateId ?? 100)
      )
    )
  );

  public createSession(images: File[], survey?: SubmitReviewJobSurveyRarams) {
    return this.selectedPatientId$.pipe(
      switchMap((id) =>
        this.imageService.createPatientSession(id, images, true, survey).pipe(
          switchMap(() =>
            this.selectedPatientSubscriptionPlan$.pipe(
              switchMap((plan) => {
                if (plan === SubscriptionPlan.Basic) {
                  return this.getProductsFreeScans();
                }

                return of(null);
              })
            )
          ),
          map(() => this.updateSelectedPatient({ id }))
        )
      )
    );
  }

  recommendations$ = this.selectedPatientId$
    .pipe(
      keepDefined(),
      distinctUntilDeepValueChanged(),
      switchMap((id) =>
        this.riskEvalService
          .getPatientRecommendations(id as string)
          .pipe(shareReplay(1))
      )
    )
    .pipe(shareReplay(1));

  treatments$ = this.selectedPatientId$.pipe(
    switchMap((id) =>
      this.treatmentService
        .getPatientTreatments(id as string)
        .pipe(shareReplay(1))
    )
  );
  latestPatientRec: PatientRecommendation;

  selectedReviewJob$ = combineLatest([
    this.reviewJobs$,
    this.selectedSessionId$,
  ]).pipe(
    keepDefined(),
    map(([sessions, selectedSessionId]) =>
      sessions.find(({ id }) => id === selectedSessionId)
    ),
    keepDefined()
  );

  visibleRecommendation$ = combineLatest([
    this.recommendations$,
    this.selectedSessionId$,
  ]).pipe(
    map(([recommendations, selectedSessionId]) =>
      ReportService.selectVisibleRecommendation(
        recommendations,
        selectedSessionId
      )
    ),
    keepDefined(),
    tap((rec) => {
      this.latestPatientRec = rec;
    })
  );

  riskFactors$ = this.visibleRecommendation$.pipe(
    map((rec) => rec.riskFactors)
  );

  riskLevel$ = this.visibleRecommendation$.pipe(map((rec) => rec.riskLevel));

  recommendationNote$ = this.visibleRecommendation$.pipe(
    map((rec) => rec.note)
  );

  organizations$ = this.organizationService
    .getAllOrganizations()
    .pipe(shareReplay(1));

  public readonly patientWithOrganizations$ = combineLatest([
    this.selectedPatient$,
    this.organizations$,
  ]).pipe(
    map(([patient, organizations]) => {
      const patientOrganizationNames =
        (patient.organizations
          ?.map((orgId) => organizations.find((org) => org.id === orgId)?.name)
          .filter(Boolean) as string[]) ?? [];

      return {
        ...patient,
        organizations: patientOrganizationNames,
      };
    })
  );

  patientOrganizationsNames$ = combineLatest([
    this.selectedPatient$,
    this.organizations$,
  ]).pipe(
    map(([patient, organizations]) =>
      organizations.map((organization) => {
        if (
          patient?.organizations &&
          patient?.organizations.includes(organization.id)
        ) {
          return organization.name.toLocaleLowerCase();
        }
        return null;
      })
    )
  );

  loading$ = combineLatest([
    this.loadPatientErred$,
    this.selectedPatient$,
    this.sessionIndexedImages$,
    this.recommendations$,
    this.treatments$,
    this.reviewJobs$,
  ]).pipe(
    map(([loadPatientErred, ...dataDeps]) => {
      if (loadPatientErred) return false;
      return dataDeps.some((v) => isNullable(v));
    }),
    startWith(true)
  );

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly patientService: PatientService,
    private readonly riskEvalService: RiskEvaluationApiService,
    private readonly treatmentService: TreatmentService,
    private readonly reviewJobsService: ReviewJobService,
    private readonly userService: UserService,
    private readonly imageService: ImageService,
    private readonly organizationService: OrganizationService,
    private readonly membershipService: MembershipService,
    private readonly dialog: MatDialog,
    private readonly analyticsService: AnalyticsService
  ) {
    // load patient data eagerly
    this._subs.sink = this.selectedPatient$.subscribe();
    this.initReport();
    this._subs.sink = this.visibleRecommendation$.subscribe();
  }

  updatePatient(data: Partial<KellsPatient>) {
    return this.selectedPatientId$.pipe(
      switchMap((patientId) =>
        this.patientService
          .updatePatient({ ...data, id: patientId })
          .pipe(tap(() => this.updateSelectedPatient({ id: patientId })))
      )
    );
  }

  updateSelectedPatient(args: { id: string } | { email: string }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { id, email } = args as any;
    if (isNullable(id) && isNullable(email)) {
      throw new Error(
        "cannot update selected patient if neither ID nor email is provided."
      );
    }

    if (isDefined(id)) {
      this.patientSelectionUpdate$.next({
        type: PatientSelectionUpdateType.Id,
        id,
      });
    } else if (isDefined(email)) {
      this.patientSelectionUpdate$.next({
        type: PatientSelectionUpdateType.Email,
        email,
      });
    }
  }

  updateSelectedSession(sessionId: string) {
    this.selectedSessionId$.next(sessionId);
  }

  selectedSessionDate$ = this.selectedReviewJob$.pipe(
    map((job) => this.normalizeSessionDate(job?.sessionDate)),
    tap((sessionDate) => (this.selectedSessionDate = sessionDate))
  );

  private normalizeSessionDate(dateString: string) {
    return this.datePipe.transform(dateString, "yyyy-M-d") ?? dateString;
  }

  findingsByTooth: Map<string, FindingsByTooth> = new Map<
    string,
    FindingsByTooth
  >();
  findingsByTooth$: BehaviorSubject<
    Map<string, FindingsByTooth>
  > = new BehaviorSubject<Map<string, FindingsByTooth>>(this.findingsByTooth);

  findingsByImage = new Map<string, FindingsByImage>();
  findingsByImage$: BehaviorSubject<
    Map<string, FindingsByImage>
  > = new BehaviorSubject<Map<string, FindingsByImage>>(this.findingsByImage);

  // eslint-disable-next-line class-methods-use-this
  createFindingsByTooth(
    fnd: RenderableFindingBase,
    treatments: Treatment[],
    session: string,
    sessionId: string
  ): FindingsByTooth {
    const obj: FindingsByTooth = {
      session,
      sessionId,
      tooth_id: fnd.tooth,
      name: TOOTH_NAMES[Number.parseInt(fnd.tooth, 10)],
      toothAppearances: 0,
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,
      allFindings: [fnd],
      xrays: [],
      images: [],
      allCaries: [],
      treatments,
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
    };
    switch (fnd.type) {
      case FindingType.Tooth:
        obj.tooth!.push(fnd);
        break;
      case FindingType.Material:
        obj.material!.push(fnd);
        break;
      case FindingType.BoneLoss:
        obj.boneLoss!.push(fnd);
        break;
      case FindingType.Fracture:
        obj.fracture!.push(fnd);
        break;
      case FindingType.Calculus:
        obj.calculus!.push(fnd);
        break;
      case FindingType.Infection:
        obj.infection!.push(fnd);
        break;
      case FindingType.DefectiveRestoration:
        obj.defectiveRestoration!.push(fnd);
        break;
      case FindingType.Plaque:
        obj.plaque!.push(fnd);
        break;
      case FindingType.GumRecession:
        obj.gumRecession!.push(fnd);
        break;
      case FindingType.GumInflammation:
        obj.gumInflammation!.push(fnd);
        break;
      case FindingType.MissingTooth:
        obj.missingTooth!.push(fnd);
        break;
      case FindingType.Caries:
        obj.allCaries!.push(fnd);
        if (fnd.stage === CariesStageTypes.Initial) {
          obj.initial!.push(fnd);
        } else if (fnd.stage === CariesStageTypes.Moderate) {
          obj.moderate!.push(fnd);
        } else if (fnd.stage === CariesStageTypes.Advanced) {
          obj.advanced!.push(fnd);
        }
    }

    return obj;
  }

  // eslint-disable-next-line class-methods-use-this
  updateFindingsByTooth = (
    obj: FindingsByTooth,
    fnd: RenderableFindingBase
  ): FindingsByTooth => {
    obj.allFindings.push(fnd);
    switch (fnd.type) {
      case FindingType.Tooth:
        obj.tooth!.push(fnd);
        break;
      case FindingType.Material:
        obj.material!.push(fnd);
        break;
      case FindingType.BoneLoss:
        obj.boneLoss!.push(fnd);
        break;
      case FindingType.Fracture:
        obj.fracture!.push(fnd);
        break;
      case FindingType.Calculus:
        obj.calculus!.push(fnd);
        break;
      case FindingType.Infection:
        obj.infection!.push(fnd);
        break;
      case FindingType.DefectiveRestoration:
        obj.defectiveRestoration!.push(fnd);
        break;
      case FindingType.Plaque:
        obj.plaque!.push(fnd);
        break;
      case FindingType.GumRecession:
        obj.gumRecession!.push(fnd);
        break;
      case FindingType.GumInflammation:
        obj.gumInflammation!.push(fnd);
        break;
      case FindingType.MissingTooth:
        obj.missingTooth!.push(fnd);
        break;
      case FindingType.Caries:
        obj.allCaries!.push(fnd);
        if (fnd.stage === CariesStageTypes.Initial) {
          obj.initial!.push(fnd);
        } else if (fnd.stage === CariesStageTypes.Moderate) {
          obj.moderate!.push(fnd);
        } else if (fnd.stage === CariesStageTypes.Advanced) {
          obj.advanced!.push(fnd);
        }
    }
    return obj;
  };

  private _renderCanvas: HTMLCanvasElement;
  private _imageLoader: HTMLImageElement;

  getImageBlobs = (obj: FindingsByImage): FindingsByImage => {
    if (isDefined(obj.image_url)) {
      const img = new Image();
      img.onload = (event) => {
        const renderCanvas: HTMLCanvasElement = document.createElement(
          "CANVAS"
        ) as HTMLCanvasElement;

        // this.svg.nativeElement.setAttribute('viewBox', `0 0 ${img.naturalWidth} ${img.naturalHeight}`);
        obj.naturalWidth = img.naturalWidth;
        obj.naturalHeight = img.naturalHeight;
        renderCanvas.setAttribute("width", `${img.naturalWidth}px`);
        renderCanvas.setAttribute("height", `${img.naturalHeight}px`);
        const ctx = renderCanvas.getContext("2d");
        ctx!.drawImage(img, 0, 0);

        // this.xrayImageUrl.next('#' + this.image.id);
      };
      img.src = obj.image_url;
    }

    return obj;
  };

  // eslint-disable-next-line class-methods-use-this
  createFindingsByImage(
    image: Image,
    session: string,
    sessionId: string,
    index: number
  ): FindingsByImage {
    const obj: FindingsByImage = {
      name: `${session}_${
        !isNil(image?.templateId) ? image?.templateId : index + 1
      }`,
      session,
      sessionId,
      image,
      image_id: image.id,
      image_url: image.url,
      //name: TOOTH_NAMES[fnd.tooth],
      imageAppearances: 1,
      allFindings: image.findings as RenderableFindingBase[],
      totalCaries: 0,
      qtyAdvanced: 0,
      qtyInitial: 0,
      qtyModerate: 0,
      qtyMaterial: 0,
      qtyBoneLoss: 0,

      images: [image],
      xrays: [],
      allCaries: [],
      treatments: [],
      tooth: [],
      initial: [],
      advanced: [],
      moderate: [],
      material: [],
      boneLoss: [],
      calculus: [],
      fracture: [],
      infection: [],
      defectiveRestoration: [],
      plaque: [],
      gumRecession: [],
      gumInflammation: [],
      missingTooth: [],
      imageType: image.imageType,
    };

    image.findings.forEach((fnd) => {
      switch (fnd.type) {
        case FindingType.Tooth:
          obj.tooth!.push(fnd);
          break;
        case FindingType.Material:
          obj.material!.push(fnd);
          break;
        case FindingType.BoneLoss:
          obj.boneLoss!.push(fnd);
          break;
        case FindingType.Fracture:
          obj.fracture!.push(fnd);
          break;
        case FindingType.Calculus:
          obj.calculus!.push(fnd);
          break;
        case FindingType.Infection:
          obj.infection!.push(fnd);
          break;
        case FindingType.DefectiveRestoration:
          obj.defectiveRestoration!.push(fnd);
          break;
        case FindingType.Plaque:
          obj.plaque!.push(fnd);
          break;
        case FindingType.GumRecession:
          obj.gumRecession!.push(fnd);
          break;
        case FindingType.GumInflammation:
          obj.gumInflammation!.push(fnd);
          break;
        case FindingType.MissingTooth:
          obj.missingTooth!.push(fnd);
          break;
        case FindingType.Caries:
          obj.allCaries!.push(fnd);
          if (fnd.stage === CariesStageTypes.Initial) {
            obj.initial!.push(fnd);
          } else if (fnd.stage === CariesStageTypes.Moderate) {
            obj.moderate!.push(fnd);
          } else if (fnd.stage === CariesStageTypes.Advanced) {
            obj.advanced!.push(fnd);
          }
      }
    });
    return obj;
  }

  totalFindingsSession: number;
  totalFindingsSession$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  totalCariesSession: number;
  totalCariesSession$: BehaviorSubject<number> = new BehaviorSubject<number>(0); // observeProperty(this, 'totalCariesSession');
  totalInitialCariesSession: number;
  totalInitialCariesSession$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  ); //observeProperty(this, 'totalInitialCariesSession');
  totalModerateCariesSession: number;
  totalModerateCariesSession$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  ); // observeProperty(this, 'totalModerateCariesSession');
  totalAdvancedCariesSession: number;
  totalAdvancedCariesSession$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  ); //observeProperty(this, 'totalAdvancedCariesSession');
  totalMaterialsSession: number;
  totalMaterialsSession$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  ); //observeProperty(this, 'totalMaterialsSession');
  totalBoneLossSession: number;
  totalBoneLossSession$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  ); //observeProperty(this, 'totalBoneLossSession');

  POINTS_PER_INITIAL = 0;
  POINTS_PER_MODERATE = 0;
  POINTS_PER_ADVANCED = 0;
  treatmentsInReport$ = combineLatest([
    this.treatments$,
    this.selectedSessionId$,
  ]).pipe(
    map(([treatments, selectedSessionId]) => {
      if (isNullable(treatments)) return [];
      // only treatments in the selected session will be used as report input data
      return treatments.filter((t) => t.session === selectedSessionId);
    })
  );

  sessionXrayScore$ = this.treatmentsInReport$
    .pipe(
      map((treatments) => {
        this.POINTS_PER_INITIAL =
          (this.totalInitialCariesSession ?? 0) +
          (this.totalModerateCariesSession ?? 0) +
          (this.totalAdvancedCariesSession ?? 0);
        this.POINTS_PER_MODERATE =
          (this.totalModerateCariesSession > 0
            ? this.totalModerateCariesSession
            : 0) +
          (this.totalAdvancedCariesSession > 0
            ? this.totalAdvancedCariesSession
            : 0) +
          (this.totalModerateCariesSession > 0 ||
          this.totalAdvancedCariesSession > 0
            ? 3
            : 0);
        this.POINTS_PER_ADVANCED =
          this.totalAdvancedCariesSession > 0
            ? this.totalAdvancedCariesSession + 7
            : 0;
        const riskScore = Math.max(
          this.POINTS_PER_INITIAL,
          this.POINTS_PER_MODERATE,
          this.POINTS_PER_ADVANCED
        );

        return riskScore >= 10 ? 10 : riskScore;
      })
    )
    .pipe(shareReplay(1));

  sessionScore$ = this.treatmentsInReport$
    .pipe(
      map((treatments) => {
        const treatmentsPriorities = treatments
          .map(
            (treatment) =>
              TREATMENT_PRIORITY_BY_TYPE[
                treatment.description as TreatmentTypes
              ]
          )
          .filter((treatment) => !isNil(treatment));

        const highPriorityTreatmentsCount = treatmentsPriorities.filter(
          (priority) => priority === TreatmentPriority.High
        ).length;
        if (highPriorityTreatmentsCount) {
          const highPriorityTreatmentsCountLimited =
            highPriorityTreatmentsCount > 3 ? 3 : highPriorityTreatmentsCount;
          return 7 + highPriorityTreatmentsCountLimited;
        }
        const mediumPriorityTreatmentsCount = treatmentsPriorities.filter(
          (priority) => priority === TreatmentPriority.Medium
        ).length;
        if (mediumPriorityTreatmentsCount) {
          const mediumPriorityTreatmentsCountLimited =
            mediumPriorityTreatmentsCount > 4
              ? 4
              : mediumPriorityTreatmentsCount;
          return 3 + mediumPriorityTreatmentsCountLimited;
        }
        const lowPriorityTreatmentsCount = treatmentsPriorities.filter(
          (priority) => priority === TreatmentPriority.Low
        ).length;
        const lowPriorityTreatmentsCountLimited =
          lowPriorityTreatmentsCount > 3 ? 3 : lowPriorityTreatmentsCount;
        return lowPriorityTreatmentsCountLimited;
      }),
      pipeLog("sessionScore$")
    )
    .pipe(shareReplay(1));

  boneLossScore$ = combineLatest([
    this.totalBoneLossSession$.pipe(
      keepDefined(),
      distinctUntilDeepValueChanged()
    ),
    this.findingsByTooth$.pipe(keepDefined(), distinctUntilDeepValueChanged()),
  ])
    .pipe(
      map(([qtyBoneloss, findingsByTooth]) => {
        // let score = 0;
        // let maxSeverityAll = 0;
        // let scoreGood = 32;
        let boneLossMaxValue = 0;
        if (qtyBoneloss > 0) {
          findingsByTooth.forEach((tooth, key) => {
            // let severity = 0.1;
            tooth.boneLoss.forEach((boneData: RenderableFindingBase) => {
              boneData.bone_loss_attributes = calcMeasurement(
                boneData.bone_loss_attributes as PerioData
              );

              // const measurment_mm = boneData!.bone_loss_attributes!.measurement_mm as number;

              try {
                if (
                  (boneData!.bone_loss_attributes!.measurement_mm as number) >
                  boneLossMaxValue
                ) {
                  boneLossMaxValue = Number(
                    boneData.bone_loss_attributes.measurement_mm
                  );
                }
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log("err", err);
              }
            });
          });
        }
        return boneLossMaxValue;
      })
    )
    .pipe(shareReplay(1), pipeLog("boneLossScore$"));

  computeFindingsByTooth() {
    this._subs.sink = combineLatest([
      this.images$.pipe(keepDefined(), distinctUntilKeyChanged("length")),
      this.selectedSessionDate$,
      this.selectedSessionId$,
      this.treatments$.pipe(keepDefined(), distinctUntilKeyChanged("length")),
    ])
      .pipe(
        // filter(images => isDefined(images) && Object.keys(images).length > 0),
        // map((images) => {
        //   if (!images) return;
        //   return images[state.sessionDates?.selected ?? ""];
        // }),

        map(
          ([images, session, sessionId, treatments]: [
            any[],
            string,
            string,
            Treatment[]
          ]) => {
            this.findingsByTooth = new Map<string, FindingsByTooth>();
            images.forEach((img: any, index) => {
              let imgData = this.findingsByImage.get(img.url);
              if (!isDefined(imgData)) {
                imgData = this.createFindingsByImage(
                  img,
                  session,
                  sessionId,
                  index
                ) as FindingsByImage;

                if (
                  isDefined(treatments) &&
                  treatments.length > 0 &&
                  isDefined(img.treatments)
                ) {
                  imgData = imgData as FindingsByImage;
                  (imgData as FindingsByImage).tooth.forEach((tooth) => {
                    imgData!.treatments = (imgData!
                      .treatments as Treatment[]).concat(
                      (treatments as Treatment[]).filter(
                        (t) => t?.tooth === tooth?.tooth
                      )
                    );
                  });
                }

                this.findingsByImage.set(imgData.image_url, imgData);
              }

              img.findings.forEach((fnd: KellsFinding) => {
                if (fnd.tooth) {
                  let tooth = this.findingsByTooth.get(fnd.tooth);
                  if (!tooth) {
                    tooth = this.createFindingsByTooth(
                      fnd as RenderableFindingBase,
                      img.treatment,
                      session,
                      sessionId
                    );
                    tooth.treatments = treatments.filter(
                      (t) => t.tooth === fnd.tooth
                    );
                  } else {
                    tooth = this.updateFindingsByTooth(
                      tooth,
                      fnd as RenderableFindingBase
                    );
                  }
                  tooth.xrays.push(img.url);

                  this.findingsByTooth.set(tooth!.tooth_id as string, tooth);
                }
              });
            });

            if (
              !this.findingsByTooth.size &&
              images.length &&
              images.every((image) => image.imageType === ImageType.Photo)
            ) {
              this.reportDataLoadComplete.next({
                report: this.latestPatientRec!,
                selectedSession: session,
                findingsByTooth: this.findingsByTooth,
                findingsByImage: this.findingsByImage,
                status: "loaded",
              });
            }

            return this.findingsByTooth;
          }
        ),

        pipeLog("findingsByTooth")
      )
      .pipe(
        map((fnds: Map<string, FindingsByTooth>) => {
          this.totalFindingsSession = 0;
          this.totalCariesSession = 0;
          this.totalInitialCariesSession = 0;
          this.totalModerateCariesSession = 0;
          this.totalAdvancedCariesSession = 0;
          this.totalMaterialsSession = 0;
          this.totalBoneLossSession = 0;
          for (const fnd of fnds.values()) {
            fnd.toothAppearances = fnd.tooth.length;
            fnd.qtyInitial = fnd.initial.length;
            fnd.qtyModerate = fnd.moderate.length;
            fnd.qtyAdvanced = fnd.advanced.length;
            fnd.qtyMaterial = fnd.material.length;
            fnd.qtyBoneLoss = fnd.boneLoss.length;
            fnd.totalCaries =
              fnd.qtyInitial + fnd.qtyModerate + fnd.qtyAdvanced;
            this.totalInitialCariesSession += fnd.qtyInitial;
            this.totalModerateCariesSession += fnd.qtyModerate;
            this.totalAdvancedCariesSession += fnd.qtyAdvanced;
            this.totalCariesSession += fnd.totalCaries;
            this.totalMaterialsSession += fnd.qtyMaterial;
            this.totalBoneLossSession += fnd.qtyBoneLoss;
            this.totalFindingsSession += fnd.allFindings.length;
          }

          this.totalFindingsSession$.next(this.totalFindingsSession);

          this.totalInitialCariesSession$.next(this.totalInitialCariesSession);
          this.totalModerateCariesSession$.next(
            this.totalModerateCariesSession
          );
          this.totalAdvancedCariesSession$.next(
            this.totalAdvancedCariesSession
          );
          this.totalCariesSession$.next(this.totalCariesSession);
          this.totalMaterialsSession$.next(this.totalMaterialsSession);
          this.totalBoneLossSession$.next(this.totalBoneLossSession);

          this.findingsByTooth$.next(fnds);

          if (fnds.size) {
            this.reportDataLoadComplete.next({
              report: this.latestPatientRec!,
              selectedSession: this.selectedSessionDate,
              findingsByTooth: fnds,
              findingsByImage: this.findingsByImage,
              status: "loaded",
            });
          }

          return fnds;
        })
      )
      .pipe(shareReplay(1))
      .subscribe();
  }

  reportDataLoadComplete: BehaviorSubject<CoreDataLoaded> = new BehaviorSubject<CoreDataLoaded>(
    { status: "loading" }
  );
  //{ report?: PatientRecommendation, findingsByTooth?: Map<string, FindingsByTooth> }> = new BehaviorSubject<{ report?: PatientRecommendation, findingsByTooth?: Map<string, FindingsByTooth> }>({});
  reportDataLoadComplete$ = this.reportDataLoadComplete
    .asObservable()
    .pipe(
      keepDefined(),
      filter(({ report, findingsByTooth, findingsByImage, status }) => {
        // return isDefined(report) &&  status === 'loaded';
        return isDefined(findingsByTooth) && status === "loaded";
        // return isDefined(report) && isDefined(findingsByTooth) && (findingsByTooth.size > 0);
      })
    )
    .pipe(shareReplay(1));

  formRiskFactors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    []
  );
  formNote$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  formRiskLevel$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  reportForm: FormGroup;
  reportForm$: Observable<FormGroup> = observeProperty(this, "reportForm").pipe(
    keepDefined()
  );

  initReport(overrides?: Partial<PatientRecommendation>) {
    //this._subs.unsubscribe();

    this.report$ = new BehaviorSubject<PatientRecommendation>(
      createRecommendation()
    );
    this._subs.sink = this.recommendations$.subscribe((recommendations) => {
      this.computeFindingsByTooth();
    });

    this._subs.sink = this.sessionScore$.subscribe();
    this.reportForm = this.formBuilder.group({
      patientId: [
        this.patientId,
        { updateOn: "change", validators: [Validators.required] },
      ],
      id: ["", { updateOn: "change" }],
      note: ["", { updateOn: "change", validators: [Validators.required] }],
      riskLevel: [
        "",
        { updateOn: "change", validators: [Validators.required] },
      ],
      riskFactors: ["", { updateOn: "change", validators: [] }],
    });

    this._subs.sink = this.sessionScore$
      .pipe(keepDefined(), distinctUntilDeepValueChanged())
      .subscribe((val) => {
        if (val < 4) {
          this.formRiskLevel$.next(1);
        } else if (val < 8) {
          this.formRiskLevel$.next(2);
        } else {
          this.formRiskLevel$.next(3);
        }
      });
    this._subs.sink = this.visibleRecommendation$.subscribe();

    this._subs.sink = this.visibleRecommendation$
      .pipe(keepDefined(), distinctUntilKeyChanged("createTs"))
      .subscribe((report) => {
        this.latestPatientRec = report;
        if (!this.reportForm.pending) {
          this.reportForm.patchValue(
            {
              note: report.note,
              riskLevel: report.riskLevel,
              riskFactors: report.riskFactors
                .filter((r: string) => r !== "")
                .join(","),
              id: report.id,
            },
            { onlySelf: true, emitEvent: false }
          );

          this.formNote$.next(report.note);
          this.formRiskLevel$.next(report.riskLevel);
          this.formRiskFactors$.next(report.riskFactors);
        } else {
          //undo markAsPending:
          this.reportForm.setErrors(null);
        }

        // this.reportForm.setValue({
        //   patientId: this.patientId ?? '',
        //   note: report.note ?? '',
        //   riskLevel: 2,//report.riskLevel
        //   riskFactors: report.riskFactors
        // });
      });
    this._subs.sink = this.formRiskFactors$
      .pipe(keepDefined(), distinctUntilDeepValueChanged())
      .subscribe((riskFactors) => {
        if (
          riskFactors.join(",") !== this.reportForm.controls.riskFactors.value
        ) {
          this.reportForm.controls.riskFactors.patchValue(
            riskFactors.filter((r: string) => r !== "").join(",")
          );
          this.reportForm.markAsDirty();
        }
      });
    this._subs.sink = this.riskFactors$.subscribe((riskFactors) => {
      this.formRiskFactors$.next(riskFactors.map((r) => r));
    });

    this._subs.sink = this.formNote$
      .pipe(keepDefined(), distinctUntilDeepValueChanged())
      .subscribe((note) => {
        if (note !== this.reportForm.controls.note.value) {
          this.reportForm.controls.note.patchValue(note);
          this.reportForm.markAsDirty();
        }
      });

    this._subs.sink = this.formRiskLevel$
      .pipe(keepDefined(), distinctUntilDeepValueChanged())
      .subscribe((riskLevel) => {
        if (riskLevel !== this.reportForm.controls.riskLevel.value) {
          this.reportForm.controls.riskLevel.patchValue(riskLevel);
          this.reportForm.markAsDirty();
        }
      });
    this._subs.sink = this.visibleRecommendation$
      .pipe(keepDefined())
      .subscribe((report) => {
        this.report$.next(report!);
      });

    this.selectedPatientId$.pipe(keepDefined(), distinctUntilChanged()).pipe(
      map((patientId: string) => {
        if (
          patientId !== this.reportForm.controls.patientId.value &&
          this.reportForm.controls.patientId.value !== ""
        ) {
          this.reportForm.reset({
            patientId,
            riskLevel: -1,
            note: "",
            riskFactors: "",
            id: "",
          });
        } else {
          this.reportForm.patchValue(
            { patientId },
            { onlySelf: true, emitEvent: false }
          );
        }
      })
    );
  }

  report$: BehaviorSubject<PatientRecommendation>;

  sessionSummary$ = combineLatest([
    this.selectedReviewJob$,
    this.sessionScore$,
    this.boneLossScore$,
  ]).pipe(
    keepDefined(),
    map(([session, sessionScore, boneLossScore]) => ({
      patientId: session.patientId,
      session_date: session.sessionDate,
      organization_code: session.organizationCode,
      event_code: session.eventCode,
      severity_of_cavities: sessionScore,
      severity_of_bone_loss: boneLossScore,
    }))
  );

  products$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);
  productsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  getProducts() {
    this.productsLoading$.next(true);
    return this.membershipService.getProducts().pipe(
      tap((products) => {
        this.productsLoading$.next(false);
        this.products$.next(products);
      })
    );
  }

  public readonly productsFreeScans$: BehaviorSubject<FreeScans | null> = new BehaviorSubject<FreeScans | null>(
    null
  );
  public readonly hasFreeScan$ = this.productsFreeScans$.pipe(
    keepDefined(),
    map((freeScans) => freeScans.amount > 0)
  );
  public readonly productsFreeScansLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  getProductsFreeScans() {
    this.productsFreeScansLoading$.next(true);
    return this.membershipService.getProductsFreeScans().pipe(
      tap((freeScans) => {
        this.productsFreeScansLoading$.next(false);
        this.productsFreeScans$.next(freeScans);
      }),
      catchError(() => {
        this.productsFreeScansLoading$.next(false);
        this.productsFreeScans$.next({ amount: 0 });
        return of(null);
      })
    );
  }

  checkoutPaymentLinkLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  getProductCheckoutLink(body: CheckoutLinkRequest) {
    this.checkoutPaymentLinkLoading$.next(true);
    return this.membershipService.getProductCheckoutLink(body).pipe(
      tap(() => {
        this.checkoutPaymentLinkLoading$.next(false);
      })
    );
  }

  reviewJobSurveySubject$ = new BehaviorSubject<ReviewJobSurvey | null>(null);
  reviewJobSurvey$ = this.reviewJobSurveySubject$.asObservable();

  reviewJobSurveyLoadingSubject$ = new BehaviorSubject<boolean>(false);
  reviewJobSurveyLoading$ = this.reviewJobSurveyLoadingSubject$.asObservable();

  getReviewJobSurvey() {
    this.reviewJobSurveyLoadingSubject$.next(true);

    return this.reviewJobsService.getReviewJobSurvey().pipe(
      tap((survey) => {
        this.reviewJobSurveyLoadingSubject$.next(false);
        this.reviewJobSurveySubject$.next(survey);
      })
    );
  }

  patientEligibilitySubject$ = new BehaviorSubject<PatientEligibility | null>(
    null
  );
  patientEligibility$ = this.patientEligibilitySubject$
    .asObservable()
    .pipe(map(mapPatientEligibilityResponse));

  patientEligibilityLoadingSubject$ = new BehaviorSubject<boolean>(false);
  patientEligibilityLoading$ = this.patientEligibilityLoadingSubject$.asObservable();

  patientEligibilityConnectingSubject$ = new BehaviorSubject<boolean>(false);
  patientEligibilityConnecting$ = this.patientEligibilityConnectingSubject$.asObservable();

  connectPatientEligibility(id: string, insurance: PatientInsurance) {
    this.patientEligibilityConnectingSubject$.next(true);

    return this.patientService.updatePatient({ id, ...insurance }).pipe(
      switchMap(() => this.getPatientEligibility()),
      finalize(() => this.patientEligibilityConnectingSubject$.next(false))
    );
  }

  getPatientEligibility() {
    this.patientEligibilityLoadingSubject$.next(true);

    return this.selectedPatientId$.pipe(
      switchMap((id) => {
        return this.patientService.getPatientEligibility(id).pipe(
          tap((eligibility) => {
            this.patientEligibilityLoadingSubject$.next(false);
            this.patientEligibilitySubject$.next(eligibility);
          }),
          catchError((error) => {
            this.patientEligibilityLoadingSubject$.next(false);
            this.patientEligibilitySubject$.next(null);
            return throwError(error);
          })
        );
      })
    );
  }

  openUpgradePlanModal(premiumPlan: Product) {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.set("payment", "success");
    const redirect_url = url.toString();

    return this.membershipService
      .getProductCheckoutLink({
        price_id: premiumPlan.price_id,
        redirect_url,
        cancel_url: currentUrl,
      })
      .pipe(
        tap((paymentLink) => {
          if (typeof paymentLink === "string") {
            this.analyticsService.record(NavigateToPaymentLink({}));
            window.location.href = paymentLink;
          }
        })
      );
  }

  openConfirmUpgradePlanModal(premiumPlan: Product) {
    return this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          title: "You need to upgrade your plan to unlock this service",
          requireConfirmation: true,
          okText: "Upgrade",
        },
        autoFocus: false,
        panelClass: ["reports-modal"],
      })
      .afterClosed()
      .pipe(
        tap((confirmed) => {
          if (!confirmed) return;

          return this.openUpgradePlanModal(premiumPlan).subscribe();
        })
      );
  }
}

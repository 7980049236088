<div class="modal">
  <div class="flex items-center justify-between">
    <h2 class="modal-title" mat-dialog-title>How to claim your discount</h2>

    <button
      mat-icon-button
      class="mat-button mat-icon-button transition-color"
      (click)="closeDialog()"
    >
      <i class="fa fas fa-close"></i>
    </button>
  </div>

  <mat-dialog-content>
    <div class="coupon-steps" *ngIf="data.product.discount?.type === 'coupon'">
      <div class="coupon-steps__title">Steps</div>
      <ul class="coupon-steps__list">
        <li class="coupon-steps__item">
          1. Copy the coupon code
          <span
            class="coupon-steps__item__code"
            (click)="onCouponCopied()"
            [cdkCopyToClipboard]="couponCode"
            >{{ couponCode }}
            <i
              class="fa-solid fa-copy"
              *ngIf="!isCouponCopied"
              (click)="onCouponCopied()"
            ></i>
            <i class="fa-solid fa-check" *ngIf="isCouponCopied"></i>
          </span>
          and use it at the checkout
        </li>
        <li class="coupon-steps__item">
          2. Follow the site to complete the order
        </li>
        <li class="coupon-steps__item">3. Enjoy your new product</li>
      </ul>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <a
      mat-dialog-close
      (click)="onShopNowClick()"
      [href]="data.product.productLink"
      target="_blank"
      class="pay-btn"
      >BUY NOW</a
    >
  </mat-dialog-actions>
</div>

<div class="modal-wrapper">
  <h1
    mat-dialog-title
    [ngClass]="{ centered: data.centered || data.titleCentered }"
  >
    {{ data.title }}
  </h1>
  <mat-dialog-content
    *ngIf="data.description"
    [ngClass]="{ centered: data.centered || data.contentCentered }"
  >
    <div class="description-entry">
      {{ data.description }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="data.requireConfirmation">
    <button mat-button mat-dialog-close class="cancel-btn">Cancel</button>
    <button mat-button [mat-dialog-close]="true" class="confirm-btn">
      {{data.okText ?? "OK"}}
    </button>
  </mat-dialog-actions>
  <mat-dialog-actions *ngIf="!data.requireConfirmation">
    <button mat-button mat-dialog-close class="cancel-btn">Close</button>
  </mat-dialog-actions>
</div>
